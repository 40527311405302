const english = {
    pageTitle: "Members",
    sl: "SL",
    name: "Name",
    phone: "Phone",
    email: "Email",
    action: "Action",
    member: "Member",
    addMember: "Add Member",
    editMembership: "Edit Membership",
    editMembershipForm: "Edit Membership Form",
    addMembershipForm: "Add Membership Form",
    warning : "Are you sure ?",
    yes : "Yes Delete It!",
    youWontRevertIt : "You won't be able to revert this!",
    cancel : "Cancel",
    delete : "Delete",
    submit: "Submit",
}
const malay = {
    pageTitle: "Ahli",
    sl: "SL",
    name: "Nama",
    phone: "Telefon",
    email: "Emel",
    action: "Tindakan",
    member: "Ahli",
    addMember: "Tambah Ahli",
    editMembership: "Sunting Keahlian",
    editMembershipForm: "Borang Sunting Keahlian",
    addMembershipForm: "Borang Tambah Keahlian",
    warning : "Adakah anda pasti?",
    yes : "Ya, Padamkan!",
    youWontRevertIt : "Anda tidak akan dapat mengembalikannya!",
    cancel : "Batal",
    delete : "Padam",
    submit: "Hantar",
}
const thai = {
    pageTitle: "สมาชิก",
    sl: "ลำดับ",
    name: "ชื่อ",
    phone: "โทรศัพท์",
    email: "อีเมล",
    action: "การกระทำ",
    member: "สมาชิก",
    addMember: "เพิ่มสมาชิก",
    editMembership: "แก้ไขสมาชิก",
    editMembershipForm: "แบบฟอร์มแก้ไขสมาชิก",
    addMembershipForm: "แบบฟอร์มเพิ่มสมาชิก",
    warning : "คุณแน่ใจหรือไม่?",
    yes : "ใช่ ลบเลย!",
    youWontRevertIt : "คุณจะไม่สามารถย้อนกลับได้!",
    cancel : "ยกเลิก",
    delete : "ลบ",
    submit: "ส่ง",
}
const arabic = {
    pageTitle: "الأعضاء",
    sl: "رقم",
    name: "الاسم",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    action: "الإجراء",
    member: "عضو",
    addMember: "إضافة عضو",
    editMembership: "تحرير العضوية",
    editMembershipForm: "نموذج تحرير العضوية",
    addMembershipForm: "نموذج إضافة العضوية",
    warning : "هل أنت متأكد؟",
    yes : "نعم، احذفه!",
    youWontRevertIt : "لن تتمكن من التراجع عن ذلك!",
    cancel : "إلغاء",
    delete : "حذف",
    submit: "إرسال"
}
const bengali = {
    pageTitle: "সদস্য",
    sl: "ক্রমিক",
    name: "নাম",
    phone: "ফোন",
    email: "ইমেল",
    action: "ক্রিয়া",
    member: "সদস্য",
    addMember: "সদস্য যোগ করুন",
    editMembership: "সদস্যতা সম্পাদনা করুন",
    editMembershipForm: "সদস্যতা সম্পাদনা ফর্ম",
    addMembershipForm: "সদস্যতা যোগ ফর্ম",
    warning : "আপনি কি নিশ্চিত?",
    yes : "হ্যাঁ, মুছে ফেলুন!",
    youWontRevertIt : "আপনি এটি ফিরিয়ে আনতে পারবেন না!",
    cancel : "বাতিল",
    delete : "মুছে ফেলুন",
    submit: "জমা দিন",
}

export const memberLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}