
const english = {
    dashboard: "Dashboard",
    administration : "Administration",
    adminSetup : "Admin Setup",
    title: "Title",
    country: "Country",
    city: "City",
    currency: "Currency",
    suppliersCategory: "Suppliers Category",
    timezone: "Time Zone",
    vat: "VAT",
    tax: "Tax",
    adjustmentType : "Adjustment Type",
    productManagement : "Product Management",
    brand: "Brand",
    category: "Category",
    productGroup: "Product Group",
    product: "Products",
    subCategory: "Sub Category",
    supplierManagement : "Supplier Management",
    supplier: "Supplier",
    purchase: "Purchase",
    purchaseIn: "Purchase In",
    stockManagement : "Stock Management",
    currentStock: "Current Stock",
    storeIn: "Store In",
    salesReturn: "Sales Return",
    adjustment: "Adjustment",
    transaction : "Transaction",
    transactionDetails : "Transaction Details",
    reports: "Reports",
    dailySales: "Daily Sales",
    salesSummery: "Sales Summery",
    memberWiseSales: "Member Wise Sales",
    creditSales: "Credit Sales",
    memberWiseCreditSales: "Member Wise Credit Sales",
    purchaseSummery: "Purchase Summery",
    purchaseReturn: "Purchase Return",
    supplierDue: "Supplier Due",
    stockClosing: "Stock Closing",
    stockAdjustment: "Stock Adjustment",
    productExpiry: "Product Expiry",
    profitLoss: "Profit / Loss",
    hrManagement : "HR Management",
    userRole: "User Role",
    customers: "Customers",
    settings: "Settings",
}
const malay = {
    dashboard: "Papan Pemuka",
    administration : "Pentadbiran",
    adminSetup : "Tetapan Admin",
    title: "Tajuk",
    country: "Negara",
    city: "Bandar",
    currency: "Mata Wang",
    suppliersCategory: "Kategori Pembekal",
    timezone: "Zon Masa",
    vat: "Cukai Nilai Tambah",
    tax: "Cukai",
    adjustmentType : "Jenis Pelarasan",
    productManagement : "Pengurusan Produk",
    brand: "Jenama",
    category: "Kategori",
    productGroup: "Kumpulan Produk",
    product: "Produk",
    subCategory: "Subkategori",
    supplierManagement : "Pengurusan Pembekal",
    supplier: "Pembekal",
    purchase: "Pembelian",
    purchaseIn: "Pembelian Masuk",
    stockManagement : "Pengurusan Stok",
    currentStock: "Stok Semasa",
    storeIn: "Simpan Masuk",
    salesReturn: "Pulangan Jualan",
    adjustment: "Pelarasan",
    transaction : "Transaksi",
    transactionDetails : "Butiran Transaksi",
    reports: "Laporan",
    dailySales: "Jualan Harian",
    salesSummery: "Ringkasan Jualan",
    memberWiseSales: "Jualan Mengikut Ahli",
    creditSales: "Jualan Kredit",
    memberWiseCreditSales: "Jualan Kredit Mengikut Ahli",
    purchaseSummery: "Ringkasan Pembelian",
    purchaseReturn: "Pulangan Pembelian",
    supplierDue: "Hutang Pembekal",
    stockClosing: "Penutupan Stok",
    stockAdjustment: "Pelarasan Stok",
    productExpiry: "Tamat Tempoh Produk",
    profitLoss: "Untung / Rugi",
    hrManagement : "Pengurusan Sumber Manusia",
    userRole: "Peranan Pengguna",
    customers: "Pelanggan",
    settings: "Tetapan",
}

const thai = {
    dashboard: "แดชบอร์ด",
    administration : "การบริหาร",
    adminSetup : "ตั้งค่าผู้ดูแลระบบ",
    title: "ชื่อเรื่อง",
    country: "ประเทศ",
    city: "เมือง",
    currency: "สกุลเงิน",
    suppliersCategory: "หมวดหมู่ผู้จัดหา",
    timezone: "เขตเวลา",
    vat: "ภาษีมูลค่าเพิ่ม",
    tax: "ภาษี",
    adjustmentType : "ประเภทการปรับปรุง",
    productManagement : "การจัดการสินค้า",
    brand: "ยี่ห้อ",
    category: "หมวดหมู่",
    productGroup: "กลุ่มสินค้า",
    product: "สินค้า",
    subCategory: "หมวดหมู่ย่อย",
    supplierManagement : "การจัดการผู้จัดหา",
    supplier: "ผู้จัดหา",
    purchase: "การซื้อ",
    purchaseIn: "ซื้อเข้า",
    stockManagement : "การจัดการสต็อก",
    currentStock: "สต็อกปัจจุบัน",
    storeIn: "เก็บเข้า",
    salesReturn: "คืนสินค้า",
    adjustment: "การปรับปรุง",
    transaction : "การทำธุรกรรม",
    transactionDetails : "รายละเอียดธุรกรรม",
    reports: "รายงาน",
    dailySales: "ยอดขายรายวัน",
    salesSummery: "สรุปยอดขาย",
    memberWiseSales: "ยอดขายตามสมาชิก",
    creditSales: "ยอดขายเครดิต",
    memberWiseCreditSales: "ยอดขายเครดิตตามสมาชิก",
    purchaseSummery: "สรุปการซื้อ",
    purchaseReturn: "คืนการซื้อ",
    supplierDue: "หนี้ที่ต้องชำระของผู้จัดหา",
    stockClosing: "ปิดสต็อก",
    stockAdjustment: "การปรับสต็อก",
    productExpiry: "หมดอายุสินค้า",
    profitLoss: "กำไร / ขาดทุน",
    hrManagement : "การจัดการบุคคล",
    userRole: "บทบาทผู้ใช้",
    customers: "ลูกค้า",
    settings: "การตั้งค่า",
}

const arabic = {
    dashboard: "لوحة القيادة",
    administration : "الإدارة",
    adminSetup : "إعداد المسؤول",
    title: "العنوان",
    country: "البلد",
    city: "المدينة",
    currency: "العملة",
    suppliersCategory: "فئة الموردين",
    timezone: "المنطقة الزمنية",
    vat: "ضريبة القيمة المضافة",
    tax: "الضريبة",
    adjustmentType : "نوع التعديل",
    productManagement : "إدارة المنتج",
    brand: "العلامة التجارية",
    category: "الفئة",
    productGroup: "مجموعة المنتجات",
    product: "المنتجات",
    subCategory: "الفئة الفرعية",
    supplierManagement : "إدارة الموردين",
    supplier: "المورد",
    purchase: "الشراء",
    purchaseIn: "الشراء الداخلي",
    stockManagement : "إدارة المخزون",
    currentStock: "المخزون الحالي",
    storeIn: "التخزين",
    salesReturn: "إرجاع المبيعات",
    adjustment: "التعديل",
    transaction : "المعاملة",
    transactionDetails : "تفاصيل المعاملة",
    reports: "التقارير",
    dailySales: "المبيعات اليومية",
    salesSummery: "ملخص المبيعات",
    memberWiseSales: "المبيعات حسب الأعضاء",
    creditSales: "المبيعات الائتمانية",
    memberWiseCreditSales: "المبيعات الائتمانية حسب الأعضاء",
    purchaseSummery: "ملخص المشتريات",
    purchaseReturn: "إرجاع المشتريات",
    supplierDue: "مستحق المورد",
    stockClosing: "إغلاق المخزون",
    stockAdjustment: "تعديل المخزون",
    productExpiry: "انتهاء صلاحية المنتج",
    profitLoss: "الربح / الخسارة",
    hrManagement : "إدارة الموارد البشرية",
    userRole: "دور المستخدم",
    customers: "العملاء",
    settings: "الإعدادات",
}

const bengali = {
    dashboard: "ড্যাশবোর্ড",
    administration : "প্রশাসন",
    adminSetup : "অ্যাডমিন সেটআপ",
    title: "শিরোনাম",
    country: "দেশ",
    city: "শহর",
    currency: "মুদ্রা",
    suppliersCategory: "সরবরাহকারীর বিভাগ",
    timezone: "সময় অঞ্চল",
    vat: "মূল্য সংযোজন কর",
    tax: "কর",
    adjustmentType : "সমন্বয় প্রকার",
    productManagement : "পণ্য ব্যবস্থাপনা",
    brand: "ব্র্যান্ড",
    category: "বিভাগ",
    productGroup: "পণ্য গ্রুপ",
    product: "পণ্য",
    subCategory: "উপ বিভাগ",
    supplierManagement : "সরবরাহকারী ব্যবস্থাপনা",
    supplier: "সরবরাহকারী",
    purchase: "ক্রয়",
    purchaseIn: "ক্রয় ভিতরে",
    stockManagement : "স্টক ব্যবস্থাপনা",
    currentStock: "বর্তমান স্টক",
    storeIn: "মজুদ করা",
    salesReturn: "বিক্রয় ফেরত",
    adjustment: "সমন্বয়",
    transaction : "লেনদেন",
    transactionDetails : "লেনদেনের বিবরণ",
    reports: "রিপোর্ট",
    dailySales: "দৈনিক বিক্রয়",
    salesSummery: "বিক্রয় সংক্ষিপ্তসার",
    memberWiseSales: "সদস্য অনুযায়ী বিক্রয়",
    creditSales: "ক্রেডিট বিক্রয়",
    memberWiseCreditSales: "সদস্য অনুযায়ী ক্রেডিট বিক্রয়",
    purchaseSummery: "ক্রয় সংক্ষিপ্তসার",
    purchaseReturn: "ক্রয় ফেরত",
    supplierDue: "সরবরাহকারীর বকেয়া",
    stockClosing: "স্টক বন্ধ",
    stockAdjustment: "স্টক সমন্বয়",
    productExpiry: "পণ্য মেয়াদ শেষ",
    profitLoss: "লাভ / ক্ষতি",
    hrManagement : "এইচআর ব্যবস্থাপনা",
    userRole: "ব্যবহারকারীর ভূমিকা",
    customers: "গ্রাহকরা",
    settings: "সেটিংস",
}

export const sidebar = {
    english : english,
    malay : malay,
    thai : thai,
    arabic : arabic,
    bengali: bengali
}