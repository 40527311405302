const english = {
    pageTitle: "Title List",
    addTitle: "Add Title",
    editTitle: "Edit Title",
    titleName: "Title Name",
    description: "Description",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure ?",
deleteText:"You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    title : "Title",
    sl: "SL",
    update: "Update",
    save : "Save",
    back : "Back",
message:"Your data has been deleted.",
deleted:"Deleted!"

}


const malay = {
    pageTitle: "Senarai Tajuk",
    addTitle: "Tambah Tajuk",
    editTitle: "Sunting Tajuk",
    titleName: "Nama Tajuk",
    description: "Deskripsi",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    title: "Tajuk",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!"
}


const thai = {
    pageTitle: "รายการชื่อเรื่อง",
    addTitle: "เพิ่มชื่อเรื่อง",
    editTitle: "แก้ไขชื่อเรื่อง",
    titleName: "ชื่อเรื่อง",
    description: "คำอธิบาย",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    title: "ชื่อเรื่อง",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!"
}



const arabic = {
    pageTitle: "قائمة العناوين",
    addTitle: "إضافة عنوان",
    editTitle: "تعديل العنوان",
    titleName: "اسم العنوان",
    description: "الوصف",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    title: "العنوان",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!"
}


const bengali = {
    pageTitle: "শিরোনামের তালিকা",
    addTitle: "শিরোনাম যোগ করুন",
    editTitle: "শিরোনাম সম্পাদনা করুন",
    titleName: "শিরোনামের নাম",
    description: "বিবরণ",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    title: "শিরোনাম",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!"
}



export const titleLang = {
    english: english,
    malay: malay,
    thai: thai,
    arabic: arabic,
    bengali: bengali
}