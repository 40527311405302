const english = {
    pageTitle: "VAT List",
    addVat: "Add VAT",
    editVat: "Edit VAT",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure ?",
    deleteText: "You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    vat: "VAT",
    sl: "SL",
    update: "Update",
    save: "Save",
    back: "Back",
    message: "Your data has been deleted.",
    deleted: "Deleted!"

}
const malay = {
    pageTitle: "Senarai VAT",
    addVat: "Tambah VAT",
    editVat: "Sunting VAT",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    vat: "VAT",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!"
};
const thai = {
    pageTitle: "รายการภาษีมูลค่าเพิ่ม",
    addVat: "เพิ่มภาษีมูลค่าเพิ่ม",
    editVat: "แก้ไขภาษีมูลค่าเพิ่ม",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    vat: "ภาษีมูลค่าเพิ่ม",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!"
};
const arabic = {
    pageTitle: "قائمة ضريبة القيمة المضافة",
    addVat: "إضافة ضريبة القيمة المضافة",
    editVat: "تعديل ضريبة القيمة المضافة",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    vat: "ضريبة القيمة المضافة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!"
};
const bengali = {
    pageTitle: "ভ্যাট তালিকা",
    addVat: "ভ্যাট যোগ করুন",
    editVat: "ভ্যাট সম্পাদনা করুন",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    vat: "ভ্যাট",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!"
};


export const vatLang = {
    english: english,
    malay: malay,
    thai: thai,
    arabic: arabic,
    bengali: bengali
}