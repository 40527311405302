import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import http from '../http';
import cr from '../front_assets/images/currencies.png';
import location from '../front_assets/images/map.png';
import time from '../front_assets/images/timezone.png';
import lang from '../front_assets/images/languages.png';
import { toast } from 'react-toastify';
import { memberContext } from '../navbar/auth';
import { settingsLang } from './language/pages/settingsLang';
import useLanguage from './language/useLanguage';
export default function Setting() {
    const lan = useLanguage(settingsLang);
    const [country, setCountry] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [timezone, setTimezone] = useState([]);
    const [settings, setSettings] = useState({
        location: '',
        currency: '',
        timezone: '',
    });
    const { setSetting } = useContext(memberContext);
    useEffect(() => {
        http.get(`/country`).then(res => {
            console.log(res.data.data);
            if (res.status === 200) {
                setCountry(res.data.data);
            }
        });
        http.get(`/designation`).then(res => {
            console.log(res.data.data);
            if (res.status === 200) {
                setCurrency(res.data.data);
            }
        });
        http.get(`/delivery-mode`).then(res => {
            if (res.status === 200) {
                setTimezone(res.data.data);
            }
        });
        http.get(`/settings`).then(res => {
            if (res.status === 200 && res.data.data) {
                setSettings(res.data.data);
            }
        });

    }, [])
    const language = [
        { id: 1, name: 'English' },
        { id: 2, name: 'Thai' },
        { id: 3, name: 'Malay' },
        { id: 4, name: 'Arabic' },
        { id: 5, name: 'Bengali' },

    ]
    const updateSetting = () => {
        if (settings.location || settings.currency || settings.timezone || settings.language) {
            if (settings?.id) {
                http.put(`/settings/${settings?.id}`, settings)
                    .then(res => {
                        if (res.data.status === 200) {
                            toast.success(res.data.message);
                            setSetting(res.data.data);
                        }
                    })
            } else {
                http.post('/settings', settings)
                    .then(res => {
                        if (res.data.status === 200) {
                            toast.success(res.data.message);
                            setSetting(res.data.data);
                        }
                    })
            }

        } else {
            toast.error('Please select at least one field');
        }

    }
    return (
        <div className='page-content'>
            <div className='custom-card patients-head '>
                <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-title'>
                    {lan?.pageTitle}
                </h5>
            </div>
            <div className="custom-card mt-2 p-2">
                <div className="row">
                    <div className="col-3">
                        <div className=" custom-card p-2 m-2">
                            <div className="text-center mb-3">
                                <img style={{ width: '80px' }} src={location} alt="" className="mx-auto img-fluid" />
                            </div>
                            <h5 className='text-center mb-2'>{lan?.location}</h5>

                            <Select
                                options={country}
                                onChange={(country) => setSettings({ ...settings, location: country.country_name })}
                                placeholder={'Select'}
                                // isDisabled={true}
                                getOptionLabel={(country) =>
                                    `${country.country_name}`
                                }
                                getOptionValue={(country) => `${country.country_name}`}
                                value={country.filter(
                                    (supplier) =>
                                        supplier.country_name === settings?.location,
                                )}
                            />
                        </div>

                    </div>
                    <div className="col-3">
                        <div className=" custom-card p-2 m-2">
                            <div className="text-center mb-3">
                                <img style={{ width: '80px' }} src={cr} alt="" className="mx-auto img-fluid" />
                            </div>
                            <h5 className='text-center mb-2'>{lan?.currency}</h5>

                            <Select
                                options={currency}
                                onChange={(currency) => setSettings({ ...settings, currency: currency.designation_name })}
                                placeholder={'Select'}
                                // isDisabled={true}
                                getOptionLabel={(currency) =>
                                    `${currency.designation_name}`
                                }
                                getOptionValue={(currency) => `${currency.designation_name}`}
                                value={currency.filter(
                                    (supplier) =>
                                        supplier.designation_name === settings?.currency,
                                )}
                            />
                        </div>

                    </div>
                    <div className="col-3">
                        <div className=" custom-card p-2 m-2">
                            <div className="text-center mb-3">
                                <img style={{ width: '80px' }} src={time} alt="" className="mx-auto img-fluid" />
                            </div>
                            <h5 className='text-center mb-2'>{lan?.timeZone}</h5>
                            <Select
                                options={timezone}
                                onChange={(timezone) => setSettings({ ...settings, timezone: timezone.delivery_mode_name })}
                                placeholder={'Select'}
                                // isDisabled={true}
                                getOptionLabel={(timezone) =>
                                    `${timezone.delivery_mode_name}`
                                }
                                getOptionValue={(timezone) => `${timezone.delivery_mode_name}`}
                                value={timezone.filter(
                                    (supplier) =>
                                        supplier.delivery_mode_name === settings?.timezone,
                                )}
                            />
                        </div>

                    </div>
                    <div className="col-3">
                        <div className=" custom-card p-2 m-2">
                            <div className="text-center mb-3">
                                <img style={{ width: '80px' }} src={lang} alt="" className="mx-auto img-fluid" />
                            </div>
                            <h5 className='text-center mb-2'>{lan?.language}</h5>
                            <Select
                                options={language}
                                onChange={(language) => setSettings({ ...settings, language: language.name })}
                                placeholder={'Select'}
                                // isDisabled={true}
                                getOptionLabel={(language) =>
                                    `${language.name}`
                                }
                                getOptionValue={(language) => `${language.name}`}
                                value={language.filter(
                                    (supplier) =>
                                        supplier.name === settings?.language,
                                )}
                            />
                        </div>

                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <button onClick={updateSetting} className="btn btn-primary btn-sm me-3">{lan?.save}</button>
                    </div>
                </div>
                <div className="mt-5 pt-5"></div>
            </div>

        </div>
    )
}
