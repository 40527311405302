const english = {
    pageTitle: "Store In",
    addStoreIn: "Add Store In",
    sl: "Sl",
    storeInNo: "Store In No",
    deliveryDate: "Delivery Date",
    totalCost: "Total Cost",
    paid: "Paid",
    due: "Due",
    contactNo: "Contact No",
    status: "Status",
    action: "Action",
    storeIn: "Store In",
    purchaseInNo: "Purchase In No",
    referenceInvoiceNo: "Reference Invoice No",
    referenceOrderNo: "Reference Order No",
    supplier: "Supplier",
    productDetails: "Product Details",
    name: "Name",
    brand: "Brand",
    expDate: "Exp. Date",
    boxType: "Box Type",
    quantity: "Quantity",
    pktSize: "Pkt Size",
    noOfBoxBottle: "No. of Box/Bottle",
    unit: "Unit",
    pp: "PP",
    bonusQty: "Bonus Qty",
    discount : "Discount",
    totalPrice: "Total Price",
    addToRack : "Add To Rack & Self",
    select: "Select",
    selectMrr: "Select Mrr No, Self & Rack First",
    payment : "Payment",
    subTotal: "Sub Total",
    vat:"Vat",
    grandTotal : "Grand Total",
    back: "Back",
    createStoreIn: "Create Store In",
    approved: "Approved",
    remarks: "Remarks",
}
const malay = {
    pageTitle: "Simpan Di",
    addStoreIn: "Tambah Simpan Di",
    sl: "No",
    storeInNo: "No. Simpan Di",
    deliveryDate: "Tarikh Penghantaran",
    totalCost: "Jumlah Kos",
    paid: "Dibayar",
    due: "Tertunggak",
    contactNo: "No. Hubungan",
    status: "Status",
    action: "Tindakan",
    storeIn: "Simpan Di",
    purchaseInNo: "No. Pembelian Di",
    referenceInvoiceNo: "No. Invois Rujukan",
    referenceOrderNo: "No. Pesanan Rujukan",
    supplier: "Pembekal",
    productDetails: "Butiran Produk",
    name: "Nama",
    brand: "Jenama",
    expDate: "Tarikh Luput",
    boxType: "Jenis Kotak",
    quantity: "Kuantiti",
    pktSize: "Saiz Pkt",
    noOfBoxBottle: "Jumlah Kotak/Botol",
    unit: "Unit",
    pp: "PP",
    bonusQty: "Kuantiti Bonus",
    discount: "Diskaun",
    totalPrice: "Jumlah Harga",
    addToRack: "Tambah ke Rak & Rak Kecil",
    select: "Pilih",
    selectMrr: "Pilih No. Mrr, Rak Kecil & Rak Dulu",
    payment: "Pembayaran",
    subTotal: "Jumlah Kecil",
    vat: "VAT",
    grandTotal: "Jumlah Besar",
    back: "Kembali",
    createStoreIn: "Cipta Simpan Di",
    approved: "Diluluskan",
    remarks: "Ulasan",
};
const thai = {
    pageTitle: "เก็บใน",
    addStoreIn: "เพิ่มเก็บใน",
    sl: "ลำดับ",
    storeInNo: "หมายเลขเก็บใน",
    deliveryDate: "วันที่ส่ง",
    totalCost: "ต้นทุนรวม",
    paid: "จ่ายแล้ว",
    due: "ค้างชำระ",
    contactNo: "หมายเลขติดต่อ",
    status: "สถานะ",
    action: "การกระทำ",
    storeIn: "เก็บใน",
    purchaseInNo: "หมายเลขสั่งซื้อ",
    referenceInvoiceNo: "หมายเลขใบกำกับอ้างอิง",
    referenceOrderNo: "หมายเลขคำสั่งซื้ออ้างอิง",
    supplier: "ผู้จัดหา",
    productDetails: "รายละเอียดสินค้า",
    name: "ชื่อ",
    brand: "แบรนด์",
    expDate: "วันที่หมดอายุ",
    boxType: "ประเภทกล่อง",
    quantity: "จำนวน",
    pktSize: "ขนาดแพ็ค",
    noOfBoxBottle: "จำนวนกล่อง/ขวด",
    unit: "หน่วย",
    pp: "PP",
    bonusQty: "จำนวนโบนัส",
    discount: "ส่วนลด",
    totalPrice: "ราคารวม",
    addToRack: "เพิ่มในชั้นวางและชั้นวางย่อย",
    select: "เลือก",
    selectMrr: "เลือกหมายเลข Mrr, ชั้นวางและชั้นวางย่อยก่อน",
    payment: "การชำระเงิน",
    subTotal: "ยอดรวมย่อย",
    vat: "ภาษีมูลค่าเพิ่ม",
    grandTotal: "ยอดรวมทั้งหมด",
    back: "กลับ",
    createStoreIn: "สร้างเก็บใน",
    approved: "อนุมัติ",
    remarks: "หมายเหตุ",
};
const arabic = {
    pageTitle: "تخزين في",
    addStoreIn: "إضافة تخزين في",
    sl: "الرقم التسلسلي",
    storeInNo: "رقم التخزين في",
    deliveryDate: "تاريخ التسليم",
    totalCost: "التكلفة الإجمالية",
    paid: "مدفوع",
    due: "مستحق",
    contactNo: "رقم الاتصال",
    status: "الحالة",
    action: "الإجراء",
    storeIn: "تخزين في",
    purchaseInNo: "رقم الشراء في",
    referenceInvoiceNo: "رقم الفاتورة المرجعية",
    referenceOrderNo: "رقم الطلب المرجعي",
    supplier: "المورد",
    productDetails: "تفاصيل المنتج",
    name: "الاسم",
    brand: "العلامة التجارية",
    expDate: "تاريخ الانتهاء",
    boxType: "نوع الصندوق",
    quantity: "الكمية",
    pktSize: "حجم العبوة",
    noOfBoxBottle: "عدد الصناديق/الزجاجات",
    unit: "الوحدة",
    pp: "PP",
    bonusQty: "كمية البونص",
    discount: "الخصم",
    totalPrice: "السعر الإجمالي",
    addToRack: "أضف إلى الرف والرف الصغير",
    select: "اختر",
    selectMrr: "اختر رقم Mrr، الرف والرف الصغير أولاً",
    payment: "الدفع",
    subTotal: "المجموع الفرعي",
    vat: "ضريبة القيمة المضافة",
    grandTotal: "المجموع الكلي",
    back: "عودة",
    createStoreIn: "إنشاء تخزين في",
    approved: "معتمد",
    remarks: "ملاحظات",
};
const bengali = {
    pageTitle: "স্টোর ইন",
    addStoreIn: "স্টোর ইন যোগ করুন",
    sl: "ক্রমিক",
    storeInNo: "স্টোর ইন নম্বর",
    deliveryDate: "বিতরণের তারিখ",
    totalCost: "মোট খরচ",
    paid: "প্রদত্ত",
    due: "বকেয়া",
    contactNo: "যোগাযোগ নম্বর",
    status: "অবস্থা",
    action: "ক্রিয়া",
    storeIn: "স্টোর ইন",
    purchaseInNo: "ক্রয় ইন নম্বর",
    referenceInvoiceNo: "রেফারেন্স ইনভয়েস নম্বর",
    referenceOrderNo: "রেফারেন্স অর্ডার নম্বর",
    supplier: "সরবরাহকারী",
    productDetails: "পণ্য বিবরণ",
    name: "নাম",
    brand: "ব্র্যান্ড",
    expDate: "মেয়াদ উত্তীর্ণের তারিখ",
    boxType: "বাক্সের ধরন",
    quantity: "পরিমাণ",
    pktSize: "প্যাকেটের আকার",
    noOfBoxBottle: "বাক্স/বোতলের সংখ্যা",
    unit: "ইউনিট",
    pp: "পিপি",
    bonusQty: "বোনাস পরিমাণ",
    discount: "ডিসকাউন্ট",
    totalPrice: "মোট মূল্য",
    addToRack: "র‌্যাক এবং শেলফে যুক্ত করুন",
    select: "নির্বাচন",
    selectMrr: "প্রথমে Mrr নম্বর, শেলফ এবং র‌্যাক নির্বাচন করুন",
    payment: "পেমেন্ট",
    subTotal: "সাব টোটাল",
    vat: "ভ্যাট",
    grandTotal: "গ্র্যান্ড টোটাল",
    back: "ফিরে যান",
    createStoreIn: "স্টোর ইন তৈরি করুন",
    approved: "অনুমোদিত",
    remarks: "মন্তব্য",
};


export const storeInLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}