import React from 'react'
import { cashierLang } from '../Components/language/pages/cashierPageLang';
import useLanguage from '../Components/language/useLanguage';

export default function Footer() {
    const lan = useLanguage(cashierLang);
    return (
        <div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">{lan?.copyright} © 2024 <a href="https://zaimahtech.com/" rel='noreferrer' target="_blank">Zaimah Technologies Ltd.</a> All rights reserved.</p>
                    <p className="text-muted">{lan?.developedBy} <a href="https://zaimahtech.com/" rel="noreferrer" target="_blank">Zaimah Technologies Ltd</a> <i className="mb-1 text-primary ms-1 icon-sm" data-feather="heart" /></p>
                </footer>
        </div>
    )
}
