const english = {
    pageTitle: "Adjustment",
    addAdjustment: "Add Adjustment",
    sl: "Sl",
    adjustmentNo: "Adjustment No",
    adjustmentDate: "Adjustment Date",
    requestedBy: "Requested By",
    phoneNo: "Phone No",
    emailAddress: "Email Address",
    contactNo: "Contact No",
    status: "Status",
    action: "Action",
    notes: "Notes",
    productDetails: "Product Details",
    name: "Name",
    brand: "Brand",
    boxType: "Box Type",
    quantity: "Quantity",
    pktSize: "Pkt Size",
    unit: "Unit",
    pp: "PP",
    mrp: "MRP",
    increase: "Increase",
    decrease : "Decrease",
    reason: "Action",
    select: "Select",
    back: "Back",
    approved: "Approved",
    remarks: "Remarks",
    save : "Save",
    totalPrice : "Total Price",
}
const malay = {
    pageTitle: "Pelarasan",
    addAdjustment: "Tambah Pelarasan",
    sl: "No",
    adjustmentNo: "No. Pelarasan",
    adjustmentDate: "Tarikh Pelarasan",
    requestedBy: "Diminta Oleh",
    phoneNo: "No. Telefon",
    emailAddress: "Alamat Emel",
    contactNo: "No. Hubungan",
    status: "Status",
    action: "Tindakan",
    notes: "Nota",
    productDetails: "Butiran Produk",
    name: "Nama",
    brand: "Jenama",
    boxType: "Jenis Kotak",
    quantity: "Kuantiti",
    pktSize: "Saiz Pkt",
    unit: "Unit",
    pp: "PP",
    mrp: "MRP",
    increase: "Tambah",
    decrease: "Kurangkan",
    reason: "Sebab",
    select: "Pilih",
    back: "Kembali",
    approved: "Diluluskan",
    remarks: "Ulasan",
    save : "Simpan",
    totalPrice : "Jumlah Harga",
};
const thai = {
    pageTitle: "การปรับ",
    addAdjustment: "เพิ่มการปรับ",
    sl: "ลำดับ",
    adjustmentNo: "หมายเลขการปรับ",
    adjustmentDate: "วันที่ปรับ",
    requestedBy: "ร้องขอโดย",
    phoneNo: "หมายเลขโทรศัพท์",
    emailAddress: "ที่อยู่อีเมล",
    contactNo: "หมายเลขติดต่อ",
    status: "สถานะ",
    action: "การกระทำ",
    notes: "บันทึก",
    productDetails: "รายละเอียดสินค้า",
    name: "ชื่อ",
    brand: "แบรนด์",
    boxType: "ประเภทกล่อง",
    quantity: "จำนวน",
    pktSize: "ขนาดแพ็ค",
    unit: "หน่วย",
    pp: "PP",
    mrp: "MRP",
    increase: "เพิ่ม",
    decrease: "ลด",
    reason: "เหตุผล",
    select: "เลือก",
    back: "กลับ",
    approved: "อนุมัติ",
    remarks: "ข้อสังเกต",
    save : "บันทึก",
    totalPrice : "ราคารวม",
};
const arabic = {
    pageTitle: "تعديل",
    addAdjustment: "إضافة تعديل",
    sl: "الرقم التسلسلي",
    adjustmentNo: "رقم التعديل",
    adjustmentDate: "تاريخ التعديل",
    requestedBy: "طلب بواسطة",
    phoneNo: "رقم الهاتف",
    emailAddress: "عنوان البريد الإلكتروني",
    contactNo: "رقم الاتصال",
    status: "الحالة",
    action: "الإجراء",
    notes: "ملاحظات",
    productDetails: "تفاصيل المنتج",
    name: "الاسم",
    brand: "العلامة التجارية",
    boxType: "نوع الصندوق",
    quantity: "الكمية",
    pktSize: "حجم العبوة",
    unit: "الوحدة",
    pp: "PP",
    mrp: "MRP",
    increase: "زيادة",
    decrease: "نقصان",
    reason: "السبب",
    select: "اختر",
    back: "عودة",
    approved: "معتمد",
    remarks: "ملاحظات",
    save : "حفظ",
    totalPrice : "السعر الإجمالي",
};
const bengali = {
    pageTitle: "সংশোধন",
    addAdjustment: "সংশোধন যোগ করুন",
    sl: "ক্রমিক",
    adjustmentNo: "সংশোধন নম্বর",
    adjustmentDate: "সংশোধনের তারিখ",
    requestedBy: "অনুরোধকারী",
    phoneNo: "ফোন নম্বর",
    emailAddress: "ইমেল ঠিকানা",
    contactNo: "যোগাযোগ নম্বর",
    status: "অবস্থা",
    action: "ক্রিয়া",
    notes: "মন্তব্য",
    productDetails: "পণ্য বিবরণ",
    name: "নাম",
    brand: "ব্র্যান্ড",
    boxType: "বাক্সের ধরন",
    quantity: "পরিমাণ",
    pktSize: "প্যাকেটের আকার",
    unit: "ইউনিট",
    pp: "পিপি",
    mrp: "এমআরপি",
    increase: "বৃদ্ধি",
    decrease: "হ্রাস",
    reason: "কারণ",
    select: "নির্বাচন",
    back: "ফিরে যান",
    approved: "অনুমোদিত",
    remarks: "মন্তব্য",
    save : "সংরক্ষণ",
    totalPrice : "মোট মূল্য",
};

export const adjustmentLang= {
    english,
    malay,
    thai,
    arabic,
    bengali
}