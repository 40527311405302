
import React from 'react';
import { useState } from 'react';
import http from '../../../http';
import Swal from 'sweetalert2';
import {  useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { productLang } from '../../../Components/language/pages/productLang';
import useLanguage from '../../../Components/language/useLanguage';

function EditDrugs() {
  const lan = useLanguage(productLang);
  const navigate = useNavigate();
  const { id } = useParams();
  const [errors, setError] = useState([]);
  // const [picture, setPicture] = useState([]);
  const [picture, setPicture] = useState();

  const [category, setCategory] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [group, setGroup] = useState([]);
  const [form_data, setFormData] = useState({
    src_primary_key: '',
    generic_id: '',
    usual_provider_id: '',
    drug_description: '',
    strength: '',
    macrohealth_sg: '',
    mims_sg: '',
    mims_type: '',
    guid: '',
    drug_name: '',
    qty: '',
    rpts: '',
    box_size: 1,
    tgp: '',
    restriction: '',
    brand_id: '',
    category_id: '',
    sub_category_id: '',
    stock: '',
    drug_code: '',
    class: '',
    batch: '',
    expiry_date: new Date(),
    price: '',
    offer_price: '',
    generic_Name: '',

    drug_discount: '',
    cash_drug_discount: '',
    card_drug_discount: '',
    digital_drug_discount: '',

    drug_weight: '',
    main_image: '',
    summary: '',
    condition: '',
    status: '',
  });
  useEffect(() => {
    http.get(`drugs/${id}/edit`).then((res) => {
      if (res.data.status === 200) {
        setFormData(res.data.data);

        if (res.data.academic.length > 0) {
          setAcademicArray(res.data.academic);
          setscan_copy_Academic(res.data.academic);
        }
      } else {
        setError(res.data.errors);
      }
    });
    http.get('brand').then((res) => {
      setBrand(res.data.data);
    });
    // http.get('category').then((res) => {
    //   setCategory(res.data.data);
    // });
    // http.get('subcategory').then((res) => {
    //   setSubCategory(res.data.data);
    // });
    http.get(`/group`).then(res => {
      if (res.status === 200) {
        setGroup(res.data.data);
      }
    });
  }, []);
  useEffect(() => {
    if (form_data?.group_id) {
      http.get(`category-by-group/${form_data?.group_id}`).then((res) => {
        setCategory(res.data.data);
      })
    }
    if (form_data?.category_id) {
      http.get(`sub-category-by-category/${form_data?.category_id}`).then((res) => {
        setSubCategory(res.data.data);
      });
    }
  }, [form_data?.group_id, form_data?.category_id]);
  const handleInput = (e) => {
    if (e.target.name === 'brand_id') {
      setFormData({
        ...form_data,
        brand_id: e.target.value,
        manufacturer: brand.find(
          (x) => parseInt(x.id) === parseInt(e.target.value),
        )?.title,
      });
    } else {
      setFormData({
        ...form_data,
        [e.target.name]: e.target.value,
      });
    }
  };
  const [docImage_error, setdocImage_error] = useState();
  const [image_error, setimage_error] = useState();
  const [imageUrl, setimageUrl] = useState();

  const handleimageUrl = (event) => {
    if (event.target.files[0].size < 2000048) {
      setPicture(event.target.files[0]);
      setimage_error(null);
    } else {
      setimage_error(lan?.img);
    }
    if (
      event.target.files &&
      event.target.files[0] &&
      event.target.files[0].size < 2000048
    ) {
      setimageUrl(URL.createObjectURL(event.target.files[0]));
    } else {
      setimage_error(lan?.img);
    }
  };
  const closeImage = () => {
    setimageUrl();
    document.getElementById('imageUrl').value = '';
  };

  const [AcademicArray, setAcademicArray] = useState([
    {
      id: '',
      drugs_master_id: '',
      // scan_copy_title: ''
    },
  ]);

  const [scan_copy_Academic, setscan_copy_Academic] = useState([
    { scan_copy: '' },
  ]);

  const handleChangeAcademic = (e, index) => {
    const { name, value } = e.target;
    const list = [...AcademicArray];
    list[index][name] = value;
    setAcademicArray(list);
  };
  const handle_Academic_File = (e, index) => {
    if (e.target.files[0].size < 2000048) {
      const { name } = e.target;

      const fileList = [...scan_copy_Academic];

      fileList[index][name] = e.target.files[0];

      setscan_copy_Academic(fileList);

      setdocImage_error(null);
    } else {
      setdocImage_error(
        lan?.img,
      );
    }
  };
  const handleAddInput = () => {
    setAcademicArray([
      ...AcademicArray,
      {
        id: '',
        // scan_copy_title: '',
        scan_copy: [],
      },
    ]);

    setscan_copy_Academic([
      ...scan_copy_Academic,
      {
        scan_copy: '',
      },
    ]);
  };

  const handleRemoveInput = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this data!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const list = [...AcademicArray];
        list.splice(index, 1);
        setAcademicArray(list);

        const listFile = [...scan_copy_Academic];
        listFile.splice(index, 1);
        setscan_copy_Academic(list);

        if (AcademicArray[index].id !== '') {
          http
            .delete(`/destroy-drugs-images/${AcademicArray[index].id}`)
            .then((res) => {
              console.log('Academic row Detele');
            });
        }

        Swal.fire('Deleted!', 'Your data has been deleted.', 'success');
      }
    });
  };

  const submitFormData = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append(
      'src_primary_key',
      form_data.src_primary_key == null ? '' : form_data.src_primary_key,
    );
    formData.append(
      'generic_id',
      form_data.generic_id == null ? '' : form_data.generic_id,
    );
    formData.append(
      'usual_provider_id',
      form_data.usual_provider_id == null ? '' : form_data.usual_provider_id,
    );
    formData.append(
      'drug_description',
      form_data.drug_description == null ? '' : form_data.drug_description,
    );
    formData.append(
      'strength',
      form_data.strength == null ? '' : form_data.strength,
    );
    formData.append(
      'macrohealth_sg',
      form_data.macrohealth_sg == null ? '' : form_data.macrohealth_sg,
    );
    formData.append(
      'mims_sg',
      form_data.mims_sg == null ? '' : form_data.mims_sg,
    );
    formData.append(
      'mims_type',
      form_data.mims_type == null ? '' : form_data.mims_type,
    );
    formData.append('guid', form_data.guid == null ? '' : form_data.guid);
    formData.append(
      'drug_name',
      form_data.drug_name == null ? '' : form_data.drug_name,
    );
    formData.append(
      'drug_price',
      form_data.drug_price == null ? 0 : form_data.drug_price,
    );
    formData.append('qty', form_data.qty == null ? '' : form_data.qty);
    formData.append('rpts', form_data.rpts == null ? '' : form_data.rpts);
    formData.append('box_size', 1);
    formData.append('tgp', form_data.tgp == null ? '' : form_data.tgp);
    formData.append(
      'restriction',
      form_data.restriction == null ? '' : form_data.restriction,
    );
    formData.append(
      'brand_id',
      form_data.brand_id == null ? '' : form_data.brand_id,
    );
    formData.append(
      'group_id',
      form_data.group_id == null ? '' : form_data.group_id,
    );
    formData.append(
      'category_id',
      form_data.category_id == null ? '' : form_data.category_id,
    );
    formData.append(
      'sub_category_id',
      form_data.sub_category_id == null ? '' : form_data.sub_category_id,
    );
    formData.append('stock', form_data.stock == null ? '' : form_data.stock);
    formData.append(
      'drug_code',
      form_data.drug_code == null ? '' : form_data.drug_code,
    );
    formData.append('class', form_data.class == null ? '' : form_data.class);
    formData.append('batch', form_data.batch == null ? '' : form_data.batch);
    formData.append(
      'expiry_date',
      form_data.expiry_date == null ? '' : form_data.expiry_date,
    );
    formData.append('price', form_data.price == null ? '' : form_data.price);
    formData.append(
      'manufacturer',
      form_data.manufacturer == null ? '' : form_data.manufacturer,
    );
    formData.append(
      'generic_Name',
      form_data.generic_Name == null ? '' : form_data.generic_Name,
    );

    formData.append(
      'boxType',
      form_data.boxType == null ? '' : form_data.boxType,
    );
    formData.append(
      'pktSize',
      form_data.pktSize == null ? '' : form_data.pktSize,
    );
    formData.append('unit', form_data.unit == null ? '' : form_data.unit);

    formData.append(
      'drug_discount',
      form_data.drug_discount == null ? '' : form_data.drug_discount,
    );
    formData.append(
      'cash_drug_discount',
      form_data.cash_drug_discount == null ? '' : form_data.cash_drug_discount,
    );
    formData.append(
      'card_drug_discount',
      form_data.card_drug_discount == null ? '' : form_data.card_drug_discount,
    );
    formData.append(
      'digital_drug_discount',
      form_data.digital_drug_discount == null
        ? ''
        : form_data.digital_drug_discount,
    );

    formData.append(
      'drug_weight',
      form_data.drug_weight == null ? '' : form_data.drug_weight,
    );
    formData.append(
      'summary',
      form_data.summary == null ? '' : form_data.summary,
    );
    formData.append(
      'condition',
      form_data.condition == null ? '' : form_data.condition,
    );
    formData.append('status', form_data.status == null ? '' : form_data.status);
    // formData.append('main_image', form_data.main_image == null ? '' : form_data.main_image);

    formData.append(
      'main_image',
      picture == null ? form_data.main_image : picture,
    );

    http.post(`drugs/update/${id}`, formData).then((res) => {
      if (res.data.status === 200) {
        AcademicArray.map((item, i) => {
          if (scan_copy_Academic[i].scan_copy !== '') {
            const academic = new FormData();
            academic.append('drugs_master_id', res.data.drugs_id);
            // academic.append('scan_copy_title', item.scan_copy_title);
            academic.append('scan_copy', scan_copy_Academic[i].scan_copy);

            if (item.id !== '') {
              http
                .post(`/update-drugs-images/${item.id}`, academic)
                .then((res) => {
                  console.log('save-doctors-academic');
                });
            } else {
              http.post('save-drugs-images', academic).then((res) => {
                console.log('save-doctors-academic');
              });
            }
          }
        });

        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: res.data.message,
          timer: 2500,
        });
        navigate('/drugs');
      } else {
        setError(res.data.errors);
      }
    });
  };
  console.log(form_data.drug_price);
  return (
    <div className='page-content'>
      <div className='custom-card patients-head '>
        <h5 className='fw-normal custom_py-3 px-2  text-start mb-2 card-title'>
          {lan?.editProduct}
          <button
            className='btn btn-sm btn-warning float-end'
            onClick={() => navigate(-1)}
          >
            <i className='fal fa-long-arrow-left'></i> {lan?.back}
          </button>
        </h5>
      </div>

      <div className='row new-patient-entry me-1'>
        <form className='' onSubmit={submitFormData}>
          <div className=''>
            <div className='custom-card mb-2'>
              <div className='pt-2 px-4'>
                <h6 className=''>
                  <b>{lan?.productDetails}</b>
                </h6>
              </div>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='mb-2'>
                      <label htmlFor='drug_code' className='form-label'>
                        {lan?.productCode}
                      </label>
                      <input
                        type='text'
                        name='drug_code'
                        disabled
                        className='form-control form-control-sm'
                        value={form_data.drug_code}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>
                        {errors.drug_code}
                      </span>
                    </div>

                    <div className='mb-2'>
                      <label htmlFor='category_id' className='form-label'>
                        {lan?.group} <span className='text-danger'>*</span>
                      </label>
                      <select
                        className='form-select form-select-sm'
                        name='group_id'
                        id='group_id'
                        value={form_data.group_id}
                        onChange={handleInput}
                      >
                        <option selected='' disabled=''>
                          {lan?.select}
                        </option>
                        {group.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <span className='text-danger'>
                        {errors.group_id}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='drug_price' className='form-label'>
                        {lan?.purchasePrice} <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='number'
                        name='drug_price'
                        className='form-control form-control-sm'
                        max={
                          form_data.drug_price
                            ? form_data.price
                            : form_data.price
                        }
                        min={0}
                        onKeyUp={(e) => {
                          if (
                            parseFloat(e.target.value) >
                            parseFloat(form_data.price)
                          ) {
                            setError({
                              ...errors,
                              drug_price:
                                "Purchase Price can't be greater than MRP Price",
                            });
                          } else {
                            setError({
                              ...errors,
                              drug_price: '',
                            });
                          }
                        }}
                        value={form_data.drug_price}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>
                        {errors.drug_price ? errors.drug_price : null}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='boxType' className='form-label'>
                        {lan?.boxType} <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        name='boxType'
                        className='form-control form-control-sm'
                        value={form_data.boxType}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.boxType}</span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='class' className='form-label'>
                        {lan?.class}
                      </label>
                      <input
                        type='text'
                        name='class'
                        className='form-control form-control-sm'
                        value={form_data.class}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.class}</span>
                    </div>


                    <div className='mb-3'>
                      <label htmlFor='main_image' className='form-label'>
                        {lan?.mainImage}
                      </label>

                      <input
                        type='file'
                        name='main_image'
                        className='form-control form-control-sm'
                        onChange={handleimageUrl}
                        id='imageUrl'
                        accept='image/jpg,image/jpeg,image/gif,image/png'
                      />
                      {image_error == null ? (
                        <p className='doc_image_size'>
                          {lan?.img}
                        </p>
                      ) : (
                        <p className='photo_size_error'>{image_error}</p>
                      )}

                      {imageUrl == null ? form_data.image ? (
                        <img
                          src={`${global.img_url}/files/drugs/${form_data.image}`}
                          width='100'
                          className='imageUrlPreview'
                          alt='preview'
                        />
                      ) :"" : (
                        <div className='photo_close'>
                          <img
                            src={imageUrl}
                            className='photo_preview_url'
                            width='100'
                            alt='preview'
                          />
                          <i
                            onClick={closeImage}
                            className='far fa-times-circle'
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='mb-2'>
                      <label
                        htmlFor='macrohealth_sg'
                        className='form-label'
                      >
                        {lan?.productName} <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        name='macrohealth_sg'
                        className='form-control form-control-sm'
                        value={form_data.macrohealth_sg}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>
                        {errors?.macrohealth_sg}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='category_id' className='form-label'>
                        {lan?.category} <span className='text-danger'>*</span>
                      </label>
                      <select
                        className='form-select form-select-sm'
                        name='category_id'
                        id='category_id'
                        value={form_data.category_id}
                        onChange={handleInput}
                      >
                        <option selected='' disabled=''>
                          {lan?.select}
                        </option>
                        {category.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                      <span className='text-danger'>
                        {errors.category_id}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='price' className='form-label'>
                        {lan?.mrpPrice} <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='number'
                        name='price'
                        className='form-control form-control-sm'
                        value={form_data.price}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.price}</span>
                    </div>

                    
                    <div className='mb-2'>
                      <label htmlFor='pktSize' className='form-label'>
                        {lan?.packetSize}  <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        name='pktSize'
                        className='form-control form-control-sm'
                        value={form_data.pktSize}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.pktSize}</span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='batch' className='form-label'>
                        {lan?.batch}
                      </label>
                      <input
                        type='text'
                        name='batch'
                        className='form-control form-control-sm'
                        value={form_data.batch}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.batch}</span>
                    </div>

                    
                    <div className='mb-3'>
                      <label htmlFor='status' className='form-label'>
                        {lan?.multipleImage}
                      </label>

                      {AcademicArray.map((item, i) => {
                        return (
                          <div key={i}>
                            <input
                              type='file'
                              name='scan_copy'
                              onChange={(e) => handle_Academic_File(e, i)}
                              className='form-control form-control-sm'
                              accept='image/jpg,image/jpeg,image/gif,image/png,application/pdf'
                            />

                            {docImage_error == null ? (
                              <p className='doc_image_size'>
                                {lan?.img}
                              </p>
                            ) : (
                              <p className='docimage_error'>
                                {docImage_error}
                              </p>
                            )}

                            {AcademicArray.length - 1 === i && (
                              <input
                                type='button'
                                onClick={handleAddInput}
                                className='btn btn-success float-end mt-2 btn-sm'
                                value={`+ ${lan?.addMore}`}
                              />
                            )}
                            {AcademicArray.length !== 1 && (
                              <input
                                type='button'
                                onClick={() => handleRemoveInput(i)}
                                className='btn btn-warning float-end mt-2 mb-2 btn-sm mr-2 me-2'
                                value={`- ${lan?.remove}'`}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className='mb-2'>
                      <label htmlFor='brand_id' className='form-label'>
                        {lan?.brand} <span className='text-danger'>*</span>
                      </label>
                      <select
                        className='form-select form-select-sm'
                        name='brand_id'
                        id='brand_id'
                        value={form_data.brand_id}
                        onChange={handleInput}
                      >
                        <option selected='' disabled=''>
                          {lan?.select}
                        </option>
                        {brand.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                      <span className='text-danger'>{errors.brand_id}</span>
                    </div>
                    <div className='mb-2'>
                      <label
                        htmlFor='sub_category_id'
                        className='form-label'
                      >
                        {lan?.subCategory} <span className='text-danger'>*</span>
                      </label>
                      <select
                        className='form-select form-select-sm'
                        name='sub_category_id'
                        id='sub_category_id'
                        value={form_data.sub_category_id}
                        onChange={handleInput}
                      >
                        <option selected='' disabled=''>
                          {lan?.select}
                        </option>
                        {subcategory.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                      <span className='text-danger'>
                        {errors.sub_category_id}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='unit' className='form-label'>
                        {lan?.unit} <span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        name='unit'
                        className='form-control form-control-sm'
                        value={form_data.unit}
                        onChange={handleInput}
                      />
                      <span className='text-danger'>{errors.unit}</span>
                    </div>

                    <div className='mb-2'>
                      <label htmlFor='expiry_date' className='form-label'>
                        {lan?.expiryDate}
                      </label>
                      <br />
                      <ReactDatePicker
                        className='form-control form-control-sm'
                        selected={
                          form_data?.expiry_date
                            ? new Date(form_data?.expiry_date)
                            : new Date()
                        }
                        dateFormat='dd/MM/yyyy'
                        onChange={(date) => {
                          setFormData({
                            ...form_data,
                            expiry_date: date,
                          });
                        }}
                      />
                      <span className='text-danger'>
                        {errors.expiry_date}
                      </span>
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='status' className='form-label'>
                        {lan?.status}
                      </label>
                      <select
                        className='form-select form-select-sm'
                        name='status'
                        id='status'
                        onChange={handleInput}
                        value={form_data.status}
                      >
                        <option selected='' disabled=''>
                          {lan?.select}
                        </option>
                        <option value='1'>{lan?.active}</option>
                        <option value='0'>{lan?.inactive}</option>
                      </select>
                      <span className='text-danger'>{errors.status}</span>
                    </div>
                    <div className='mb-2'>
                      <label
                        htmlFor='drug_description'
                        className='form-label'
                      >
                        {lan?.description}
                      </label>
                      <textarea
                        name='drug_description'
                        value={form_data.drug_description}
                        onChange={handleInput}
                        className='form-control form-control-sm'
                        maxLength='100'
                        rows='4'
                        placeholder='Description...'
                      ></textarea>
                      <span className='text-danger'>
                        {errors.drug_description}
                      </span>
                    </div>
                    <button
                      onClick={submitFormData}
                      className='btn btn-success btn-sm text-uppercase float-end mt-2'
                    >
                      <i className='fas fa-save'></i> {lan?.update}
                    </button>
                  </div>
                  <div className='col-md-12 mt-2'>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </div>
  );
}

export default EditDrugs;
