const english = {
    pageTitle: "Sub Category List",
    addSubCategory: "Add Sub Category",
    editSubCategory: "Edit Sub Category",
    title: "Title",
    category: "Category",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure?",
    deleteText: "You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    status: "Status",
    select: "Select",
    active: "Active",
    inactive: "Inactive",
    img: "Image size must be less than 2 mb",
    preview: "preview image",
    sl: "SL",
    update: "Update",
    save: "Save",
    back: "Back",
    message: "Your data has been deleted.",
    deleted: "Deleted!",
    photo: "Photo",
    description : "Description"
}
const malay = {
    pageTitle: "Senarai Sub Kategori",
    addSubCategory: "Tambah Sub Kategori",
    editSubCategory: "Sunting Sub Kategori",
    title: "Tajuk",
    category: "Kategori",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    status: "Status",
    select: "Pilih",
    active: "Aktif",
    inactive: "Tidak Aktif",
    img: "Saiz imej mesti kurang daripada 2 mb",
    preview: "pratonton imej",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!",
    photo: "Foto",
    description: "Deskripsi"
};
const thai = {
    pageTitle: "รายการหมวดหมู่ย่อย",
    addSubCategory: "เพิ่มหมวดหมู่ย่อย",
    editSubCategory: "แก้ไขหมวดหมู่ย่อย",
    title: "ชื่อเรื่อง",
    category: "หมวดหมู่",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    status: "สถานะ",
    select: "เลือก",
    active: "ใช้งาน",
    inactive: "ไม่ใช้งาน",
    img: "ขนาดรูปภาพต้องน้อยกว่า 2 mb",
    preview: "ดูตัวอย่างรูปภาพ",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!",
    photo: "รูปภาพ",
    description: "คำอธิบาย"
};
const arabic = {
    pageTitle: "قائمة الفئات الفرعية",
    addSubCategory: "إضافة فئة فرعية",
    editSubCategory: "تعديل الفئة الفرعية",
    title: "العنوان",
    category: "الفئة",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    status: "الحالة",
    select: "اختر",
    active: "نشط",
    inactive: "غير نشط",
    img: "يجب أن يكون حجم الصورة أقل من 2 ميغابايت",
    preview: "معاينة الصورة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!",
    photo: "صورة",
    description: "وصف"
};
const bengali = {
    pageTitle: "উপবিভাগ তালিকা",
    addSubCategory: "উপবিভাগ যোগ করুন",
    editSubCategory: "উপবিভাগ সম্পাদনা করুন",
    title: "শিরোনাম",
    category: "বিভাগ",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    status: "অবস্থা",
    select: "নির্বাচন করুন",
    active: "সক্রিয়",
    inactive: "নিষ্ক্রিয়",
    img: "ছবির আকার 2 এমবি এর চেয়ে কম হতে হবে",
    preview: "ছবির পূর্বরূপ",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!",
    photo: "ছবি",
    description: "বর্ণনা"
};

export const subCategoryLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}