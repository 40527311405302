const english = {
    pageTitle: "Supplier List",
    addSupplier: "Add Supplier",
    editSupplier: "Edit Supplier",
    name: "Name",
    company: "Company",
    mobile: "Mobile",
    email: "Email",
    address: "Address",
    category: "Category",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure?",
    deleteText: "You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    status: "Status",
    select: "Select",
    active: "Active",
    inactive: "Inactive",
    img: "Image size must be less than 2 mb",
    preview: "preview image",
    sl: "SL",
    update: "Update",
    save: "Save",
    back: "Back",
    message: "Your data has been deleted.",
    deleted: "Deleted!",
    photo: "Photo",
    description : "Description",
    supplierInfo: "Supplier Info",
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    city: "City",
    country: "Country", 
    phoneNumber1: "Phone Number 1",
    phoneNumber2: "Phone Number 2",
    website: "Website",
    vat : "Vat",
    tax : "Tax",
    brands: "Brands",
    addMore: "Add More",
    remove: "Remove",
    management: "Management",
    managementInfo: "Management Information",
    title : "Title",
    picture: "Picture",
    firstName : "First Name",
    lastName : "Last Name",
    designation1 : "Designation 1st",
    designation2 : "Designation 2nd",
    fax : "Fax",
    socialMedia : "Social Media",
    facebook : "Facebook",
    twitter : "Twitter",
    linkedin : "Linkedin",
    salesContactPerson : "Sales Contact Person",
    familyName: "Family Name",
    nickName : "Nick Name",
    dob: "Date of Birth",
    nid: "NID",
    doj: "Date of Joining",
    designation : "Designation",
    emergencyNumber : "Emergency Number",
    supervisorContactInformation: "Supervisor Contact Information",
    supervisorName: "Supervisor Name",
    supervisorPhone: "Supervisor Phone",
    legalDocs : "Legal Docs",
    copy: "Copy",
    issueDate: "Issue Date",
    renewDate: "Renew Date",
    multipleImage: "Multiple Image",
    all : "All",
    commission: "Commission",
    previous : "Previous",
    next : "Next",
    salesContact : "Sales Contact",

}
const malay = {
    pageTitle: "Senarai Pembekal",
    addSupplier: "Tambah Pembekal",
    editSupplier: "Sunting Pembekal",
    name: "Nama",
    company: "Syarikat",
    mobile: "Mudah Alih",
    email: "Emel",
    address: "Alamat",
    category: "Kategori",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    status: "Status",
    select: "Pilih",
    active: "Aktif",
    inactive: "Tidak Aktif",
    img: "Saiz imej mesti kurang daripada 2 mb",
    preview: "pratonton imej",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!",
    photo: "Foto",
    description: "Deskripsi",
    supplierInfo: "Maklumat Pembekal",
    addressLine1: "Alamat Baris 1",
    addressLine2: "Alamat Baris 2",
    city: "Bandar",
    country: "Negara",
    phoneNumber1: "Nombor Telefon 1st",
    phoneNumber2: "Nombor Telefon 2nd",
    website: "Laman Web",
    vat: "VAT",
    tax: "Cukai",
    brands: "Jenama",
    addMore: "Tambah Lagi",
    remove: "Buang",
    management: "Pengurusan",
    managementInfo: "Maklumat Pengurusan",
    title: "Tajuk",
    picture: "Gambar",
    firstName: "Nama Pertama",
    lastName: "Nama Akhir",
    designation1: "Jawatan 1",
    designation2: "Jawatan 2",
    fax: "Faks",
    socialMedia: "Media Sosial",
    facebook: "Facebook",
    twitter: "Twitter",
    linkedin: "LinkedIn",
    salesContactPerson: "Orang Hubungan Jualan",
    familyName: "Nama Keluarga",
    nickName: "Nama Panggilan",
    dob: "Tarikh Lahir",
    nid: "NID",
    doj: "Tarikh Menyertai",
    designation: "Jawatan",
    emergencyNumber: "Nombor Kecemasan",
    supervisorContactInformation: "Maklumat Hubungan Penyelia",
    supervisorName: "Nama Penyelia",
    supervisorPhone: "Telefon Penyelia",
    legalDocs: "Dokumen Undang-Undang",
    copy: "Salinan",
    issueDate: "Tarikh Keluaran",
    renewDate: "Tarikh Pembaharuan",
    multipleImage: "Imej Pelbagai",
    all: "Semua",
    commission : "Komisen",
    previous : "Sebelumnya",
    next : "Seterusnya",
    salesContact : "Hubungan Jualan",
};
const thai = {
    pageTitle: "รายการซัพพลายเออร์",
    addSupplier: "เพิ่มซัพพลายเออร์",
    editSupplier: "แก้ไขซัพพลายเออร์",
    name: "ชื่อ",
    company: "บริษัท",
    mobile: "มือถือ",
    email: "อีเมล",
    address: "ที่อยู่",
    category: "หมวดหมู่",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    status: "สถานะ",
    select: "เลือก",
    active: "ใช้งาน",
    inactive: "ไม่ใช้งาน",
    img: "ขนาดรูปภาพต้องน้อยกว่า 2 mb",
    preview: "ดูตัวอย่างรูปภาพ",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!",
    photo: "รูปภาพ",
    description: "คำอธิบาย",
    supplierInfo: "ข้อมูลซัพพลายเออร์",
    addressLine1: "ที่อยู่บรรทัดที่ 1",
    addressLine2: "ที่อยู่บรรทัดที่ 2",
    city: "เมือง",
    country: "ประเทศ",
    phoneNumber1: "เบอร์โทรศัพท์ 1",
    phoneNumber2: "เบอร์โทรศัพท์ 2",
    website: "เว็บไซต์",
    vat: "ภาษีมูลค่าเพิ่ม",
    tax: "ภาษี",
    brands: "แบรนด์",
    addMore: "เพิ่มอีก",
    remove: "ลบออก",
    management: "การจัดการ",
    managementInfo: "ข้อมูลการจัดการ",
    title: "ชื่อเรื่อง",
    picture: "ภาพ",
    firstName: "ชื่อจริง",
    lastName: "นามสกุล",
    designation1: "ตำแหน่ง 1st",
    designation2: "ตำแหน่ง 2nd",
    fax: "แฟกซ์",
    socialMedia: "สื่อสังคม",
    facebook: "เฟสบุ๊ค",
    twitter: "ทวิตเตอร์",
    linkedin: "ลิงค์อิน",
    salesContactPerson: "ผู้ติดต่อฝ่ายขาย",
    familyName: "นามสกุล",
    nickName: "ชื่อเล่น",
    dob: "วันเกิด",
    nid: "รหัสประจำตัวประชาชน",
    doj: "วันที่เข้าร่วม",
    designation: "ตำแหน่ง",
    emergencyNumber: "เบอร์โทรฉุกเฉิน",
    supervisorContactInformation: "ข้อมูลติดต่อหัวหน้างาน",
    supervisorName: "ชื่อหัวหน้างาน",
    supervisorPhone: "โทรศัพท์หัวหน้างาน",
    legalDocs: "เอกสารทางกฎหมาย",
    copy: "สำเนา",
    issueDate: "วันที่ออก",
    renewDate: "วันที่ต่ออายุ",
    multipleImage: "ภาพหลายภาพ",
    commission : "ค่าคอมมิชชั่น",
    all: "ทั้งหมด",
    previous : "ก่อนหน้า",
    next: "ถัดไป",
    salesContact: "ผู้ติดต่อการขาย",
};
const arabic = {
    pageTitle: "قائمة الموردين",
    addSupplier: "إضافة مورد",
    editSupplier: "تعديل المورد",
    name: "الاسم",
    company: "الشركة",
    mobile: "الجوال",
    email: "البريد الإلكتروني",
    address: "العنوان",
    category: "الفئة",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    status: "الحالة",
    select: "اختر",
    active: "نشط",
    inactive: "غير نشط",
    img: "يجب أن يكون حجم الصورة أقل من 2 ميغابايت",
    preview: "معاينة الصورة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!",
    photo: "صورة",
    description: "وصف",
    supplierInfo: "معلومات المورد",
    addressLine1: "العنوان 1",
    addressLine2: "العنوان 2",
    city: "المدينة",
    country: "الدولة",
    phoneNumber1: "رقم الهاتف 1",
    phoneNumber2: "رقم الهاتف 2",
    website: "الموقع الإلكتروني",
    vat: "ضريبة القيمة المضافة",
    tax: "الضريبة",
    brands: "العلامات التجارية",
    addMore: "أضف المزيد",
    remove: "إزالة",
    management: "الإدارة",
    managementInfo: "معلومات الإدارة",
    title: "اللقب",
    picture: "صورة",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    designation1: "الوظيفة 1",
    designation2: "الوظيفة 2",
    fax: "الفاكس",
    socialMedia: "وسائل التواصل الاجتماعي",
    facebook: "فيسبوك",
    twitter: "تويتر",
    linkedin: "لينكد إن",
    salesContactPerson: "مسؤول الاتصال بالمبيعات",
    familyName: "اسم العائلة",
    nickName: "الكنية",
    dob: "تاريخ الميلاد",
    nid: "رقم الهوية الوطنية",
    doj: "تاريخ الانضمام",
    designation: "الوظيفة",
    emergencyNumber: "رقم الطوارئ",
    supervisorContactInformation: "معلومات الاتصال بالمشرف",
    supervisorName: "اسم المشرف",
    supervisorPhone: "هاتف المشرف",
    legalDocs: "الوثائق القانونية",
    copy: "نسخة",
    issueDate: "تاريخ الإصدار",
    renewDate: "تاريخ التجديد",
    multipleImage: "صور متعددة",
    all : "الكل",
    commission: "عمولة",
    previous: "السابق",
    next: "التالي",
    salesContact: "جهة اتصال المبيعات",
};
const bengali = {
    pageTitle: "সরবরাহকারী তালিকা",
    addSupplier: "সরবরাহকারী যোগ করুন",
    editSupplier: "সরবরাহকারী সম্পাদনা করুন",
    name: "নাম",
    company: "কোম্পানি",
    mobile: "মোবাইল",
    email: "ইমেইল",
    address: "ঠিকানা",
    category: "বিভাগ",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    status: "অবস্থা",
    select: "নির্বাচন করুন",
    active: "সক্রিয়",
    inactive: "নিষ্ক্রিয়",
    img: "ছবির আকার 2 এমবি এর চেয়ে কম হতে হবে",
    preview: "ছবির পূর্বরূপ",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!",
    photo: "ছবি",
    description: "বর্ণনা",
    supplierInfo: "সরবরাহকারীর তথ্য",
    addressLine1: "ঠিকানা লাইন ১",
    addressLine2: "ঠিকানা লাইন ২",
    city: "শহর",
    country: "দেশ",
    phoneNumber1: "ফোন নম্বর ১",
    phoneNumber2: "ফোন নম্বর ২",
    website: "ওয়েবসাইট",
    vat: "ভ্যাট",
    tax: "ট্যাক্স",
    brands: "ব্র্যান্ডস",
    addMore: "আরও যোগ করুন",
    remove: "অপসারণ",
    management: "পরিচালনা",
    managementInfo: "পরিচালনার তথ্য",
    title: "শিরোনাম",
    picture: "ছবি",
    firstName: "প্রথম নাম",
    lastName: "শেষ নাম",
    designation1: "পদবি ১",
    designation2: "পদবি ২",
    fax: "ফ্যাক্স",
    socialMedia: "সামাজিক মাধ্যম",
    facebook: "ফেসবুক",
    twitter: "টুইটার",
    linkedin: "লিংকডইন",
    salesContactPerson: "বিক্রয় যোগাযোগ ব্যক্তি",
    familyName: "পারিবারিক নাম",
    nickName: "ডাক নাম",
    dob: "জন্ম তারিখ",
    nid: "এনআইডি",
    doj: "যোগদানের তারিখ",
    designation: "পদবি",
    emergencyNumber: "জরুরী নম্বর",
    supervisorContactInformation: "তত্ত্বাবধায়কের যোগাযোগ তথ্য",
    supervisorName: "তত্ত্বাবধায়কের নাম",
    supervisorPhone: "তত্ত্বাবধায়কের ফোন",
    legalDocs: "আইনি ডকুমেন্ট",
    copy: "কপি",
    issueDate: "প্রদানের তারিখ",
    renewDate: "নবায়ন তারিখ",
    multipleImage: "একাধিক চিত্র",
    all: "সকল",
    commission: "কমিশন",
    previous : "পূর্ববর্তী",
    next : "পরবর্তী",
    salesContact : "বিক্রয় যোগাযোগ ",
};

export const supplierLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}