const english = {
    pageTitle: "Time Zone List",
    addTimezone: "Add Time Zone",
    editTimezone: "Edit Time Zone",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure ?",
    deleteText: "You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    timezone: "Time Zone",
    sl: "SL",
    update: "Update",
    save: "Save",
    back: "Back",
    message: "Your data has been deleted.",
    deleted: "Deleted!" 

}
const malay = {
    pageTitle: "Senarai Zon Waktu",
    addTimezone: "Tambah Zon Waktu",
    editTimezone: "Sunting Zon Waktu",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    timezone: "Zon Waktu",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!"
};
const thai = {
    pageTitle: "รายการเขตเวลา",
    addTimezone: "เพิ่มเขตเวลา",
    editTimezone: "แก้ไขเขตเวลา",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    timezone: "เขตเวลา",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!"
};
const arabic = {
    pageTitle: "قائمة المناطق الزمنية",
    addTimezone: "إضافة منطقة زمنية",
    editTimezone: "تعديل المنطقة الزمنية",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    timezone: "المنطقة الزمنية",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!"
};
const bengali = {
    pageTitle: "সময় অঞ্চল তালিকা",
    addTimezone: "সময় অঞ্চল যোগ করুন",
    editTimezone: "সময় অঞ্চল সম্পাদনা করুন",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    timezone: "সময় অঞ্চল",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!"
};

export const timezoneLang = {
    english: english,
    malay: malay,
    thai: thai,
    arabic: arabic,
    bengali: bengali
}