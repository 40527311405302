const english = {
    pageTitle: "Current Stock",
    sl: "SL",
    manufacturer: "Manufacturer",
    boxType: "Box Type",
    name: "Name",
    pktSize: "Pkt Size",
    noOfBox: "No of Box",
    unit: "Unit",
    pp: "PP",
    bonusQty: "Bonus Qty",
    tp: "TP",
    totalPrice: "Total Price",
    vat: "Vat",
    storeIn: "Store In",
    salesReturn: "Sales Return",
    adjustment: "Adjustment",
    stockOut: "Stock Out",
    stock: "Stock",
    all: "All",
    select: "Select",
    sampleFile: "Sample File",
    csv: "CSV",
    totalStock: "Total Stock",
    totalPurchasePrice: "Total Purchase Price (Including VAT)",
    totalSellingPrice: "Total Selling Price",
    importData: "Import Data",
    cancel: "Cancel",
    submit: "Submit",
}
const malay = {
    pageTitle: "Stok Semasa",
    sl: "No",
    manufacturer: "Pengeluar",
    boxType: "Jenis Kotak",
    name: "Nama",
    pktSize: "Saiz Pkt",
    noOfBox: "Jumlah Kotak",
    unit: "Unit",
    pp: "PP",
    bonusQty: "Kuantiti Bonus",
    tp: "TP",
    totalPrice: "Jumlah Harga",
    vat: "VAT",
    storeIn: "Simpan Di",
    salesReturn: "Pemulangan Jualan",
    adjustment: "Pelarasan",
    stockOut: "Kehabisan Stok",
    stock: "Stok",
    all: "Semua",
    select: "Pilih",
    sampleFile: "Fail Contoh",
    csv: "CSV",
    totalStock: "Jumlah Stok",
    totalPurchasePrice: "Jumlah Harga Pembelian (Termasuk VAT)",
    totalSellingPrice: "Jumlah Harga Jualan",
    importData: "Import Data",
    cancel: "Batal",
    submit: "Hantar"
};
const thai = {
    pageTitle: "สต็อกปัจจุบัน",
    sl: "ลำดับ",
    manufacturer: "ผู้ผลิต",
    boxType: "ประเภทกล่อง",
    name: "ชื่อ",
    pktSize: "ขนาดแพ็ค",
    noOfBox: "จำนวนกล่อง",
    unit: "หน่วย",
    pp: "PP",
    bonusQty: "จำนวนโบนัส",
    tp: "TP",
    totalPrice: "ราคารวม",
    vat: "ภาษีมูลค่าเพิ่ม",
    storeIn: "เก็บใน",
    salesReturn: "การคืนสินค้า",
    adjustment: "การปรับเปลี่ยน",
    stockOut: "สินค้าหมด",
    stock: "สต็อก",
    all: "ทั้งหมด",
    select: "เลือก",
    sampleFile: "ไฟล์ตัวอย่าง",
    csv: "CSV",
    totalStock: "จำนวนสต็อกทั้งหมด",
    totalPurchasePrice: "ราคารวมในการซื้อ (รวมภาษีมูลค่าเพิ่ม)",
    totalSellingPrice: "ราคารวมในการขาย",
    importData: "นำเข้าข้อมูล",
    cancel: "ยกเลิก",
    submit: "ส่ง"
};
const arabic = {
    pageTitle: "المخزون الحالي",
    sl: "الرقم التسلسلي",
    manufacturer: "الشركة المصنعة",
    boxType: "نوع الصندوق",
    name: "الاسم",
    pktSize: "حجم العبوة",
    noOfBox: "عدد الصناديق",
    unit: "الوحدة",
    pp: "PP",
    bonusQty: "كمية البونص",
    tp: "TP",
    totalPrice: "السعر الإجمالي",
    vat: "ضريبة القيمة المضافة",
    storeIn: "مخزن في",
    salesReturn: "إرجاع المبيعات",
    adjustment: "التعديل",
    stockOut: "نفاد المخزون",
    stock: "المخزون",
    all: "الكل",
    select: "اختر",
    sampleFile: "ملف عينة",
    csv: "CSV",
    totalStock: "إجمالي المخزون",
    totalPurchasePrice: "إجمالي سعر الشراء (بما في ذلك ضريبة القيمة المضافة)",
    totalSellingPrice: "إجمالي سعر البيع",
    importData: "استيراد البيانات",
    cancel: "إلغاء",
    submit: "إرسال"
};
const bengali = {
    pageTitle: "বর্তমান স্টক",
    sl: "ক্রমিক",
    manufacturer: "উৎপাদক",
    boxType: "বাক্সের ধরণ",
    name: "নাম",
    pktSize: "প্যাকেটের সাইজ",
    noOfBox: "বাক্সের সংখ্যা",
    unit: "ইউনিট",
    pp: "পিপি",
    bonusQty: "বোনাস পরিমাণ",
    tp: "টিপি",
    totalPrice: "মোট মূল্য",
    vat: "ভ্যাট",
    storeIn: "সংরক্ষণ",
    salesReturn: "বিক্রয় ফেরত",
    adjustment: "সমন্বয়",
    stockOut: "স্টক আউট",
    stock: "স্টক",
    all: "সকল",
    select: "নির্বাচন",
    sampleFile: "নমুনা ফাইল",
    csv: "CSV",
    totalStock: "মোট স্টক",
    totalPurchasePrice: "মোট ক্রয় মূল্য (ভ্যাট সহ)",
    totalSellingPrice: "মোট বিক্রয় মূল্য",
    importData: "ডেটা আমদানি",
    cancel: "বাতিল",
    submit: "জমা দিন"
};

export const currentStockLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}