import React, { useEffect } from "react";
import { useState } from "react";
import AuthUser from "./AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../front_assets/Logo_Image/greatpharmalogo.png'
import login from '../front_assets/Logo_Image/login.png'
import wave from '../front_assets/Logo_Image/wave.png'
import bg from '../front_assets/Logo_Image/bg.svg'
import avatar from '../front_assets/Logo_Image/avatar.svg'
import '../CSS/login.css'
import Swal from "sweetalert2";

export default function Login() {

    const { http, setToken } = AuthUser();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState([]);
    const [loading, setloading] = useState(false)

    const submitForm = (e) => {
        e.preventDefault();
        setloading(true);
        http.post('login', { email: email, password: password }).then((res) => {
            console.log(res);
            if (res.data.user.user_type === 'empty') {
                Swal.fire("Warning", 'Please ask admin to assign your role', "warning");
            } else {
                setToken(res.data.user, res.data.access_token);
            }
            http.get(`clear`)
            setloading(false)
        }).catch(err => {
            toast.error(err.response.data.error, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setError(err.response.data.error);
            setloading(false)
        })
    }
    useEffect(() => {
        const inputs = document.querySelectorAll(".input");

        function addcl() {
            let parent = this.parentNode.parentNode;
            parent.classList.add("focus");
        }

        function remcl() {
            let parent = this.parentNode.parentNode;
            if (this.value === "") {
                parent.classList.remove("focus");
            }
        }

        inputs.forEach(input => {
            input.addEventListener("focus", addcl);
            input.addEventListener("blur", remcl);
        });

        return () => {
            inputs.forEach(input => {
                input.removeEventListener("focus", addcl);
                input.removeEventListener("blur", remcl);
            });
        };
    }, []);


    return (
        <>
            <div className="login-container">
                <ToastContainer />
                <div>
                    <img className="wave" src={wave} alt="wave" />

                    <div className="container">
                        {/* <div className="box"> */}
                        <div className="img">
                            <img src={logo} alt="logo" style={{width:'220px' , height: '50px' , marginTop: '8px', marginLeft: '-96px', borderRadius:'10px'}} />
                            <img src={bg} alt="bg" />
                        </div>
                        <div className="login-content">
                            <form onSubmit={submitForm}>
                                <img src={avatar} alt="profile" />
                                <h2 className="title">Welcome To DigiPharma</h2>
                                <div className="input-div one">
                                    <div className="i">
                                        <i className="fas fa-user" />
                                    </div>
                                    <div className="div">
                                        <h5>Email</h5>
                                        <input type="text" name="email" onChange={e => setEmail(e.target.value)} className="input" required />
                                    </div>
                                </div>
                                <div className="input-div pass">
                                    <div className="i">
                                        <i className="fas fa-lock" />
                                    </div>
                                    <div className="div">
                                        <h5>Password</h5>
                                        <input type="password" name="password" onChange={e => setPassword(e.target.value)} className="input" required />
                                    </div>
                                </div>

                                <input type="submit" className="btn" defaultValue="Login" />
                            </form>
                        </div>
                        {/* </div> */}

                    </div>
                </div>

            </div>


        </>)
}