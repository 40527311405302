const english = {
    pageTitle: "User Role",
    sl: "Sl",
    userName: "User Name",
    phone: "Phone",
    email: "Email",
    assignedRole: "Assigned Role",
    action: "Action",
    userRole: "User Role",
    cancel: "Cancel",
    assign: "Assign",
    admin: "Admin",
    manager: "Manager",
    sales: "Sales",
    supplier: "Supplier",
    cashier: "Cashier",

}
const malay = {
    pageTitle: "Peranan Pengguna",
    sl: "SL",
    userName: "Nama Pengguna",
    phone: "Telefon",
    email: "Emel",
    assignedRole: "Peranan Diberikan",
    action: "Tindakan",
    userRole: "Peranan Pengguna",
    cancel: "Batal",
    assign: "Berikan",
    admin: "Admin",
    manager: "Pengurus",
    sales: "Jualan",
    supplier: "Pembekal",
    cashier: "Juruwang",
}
const thai = {
    pageTitle: "บทบาทผู้ใช้",
    sl: "ลำดับ",
    userName: "ชื่อผู้ใช้",
    phone: "โทรศัพท์",
    email: "อีเมล",
    assignedRole: "บทบาทที่มอบหมาย",
    action: "การกระทำ",
    userRole: "บทบาทผู้ใช้",
    cancel: "ยกเลิก",
    assign: "มอบหมาย",
    admin: "ผู้ดูแลระบบ",
    manager: "ผู้จัดการ",
    sales: "การขาย",
    supplier: "ผู้จัดจำหน่าย",
    cashier: "แคชเชียร์",
}
const arabic = {
    pageTitle: "دور المستخدم",
    sl: "الرقم",
    userName: "اسم المستخدم",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    assignedRole: "الدور المعين",
    action: "إجراء",
    userRole: "دور المستخدم",
    cancel: "إلغاء",
    assign: "تعيين",
    admin: "مدير",
    manager: "مدير",
    sales: "مبيعات",
    supplier: "مورد",
    cashier: "أمين الصندوق",
}
const bengali = {
    pageTitle: "ব্যবহারকারীর ভূমিকা",
    sl: "ক্রমিক",
    userName: "ব্যবহারকারীর নাম",
    phone: "ফোন",
    email: "ইমেল",
    assignedRole: "নির্ধারিত ভূমিকা",
    action: "ক্রিয়া",
    userRole: "ব্যবহারকারীর ভূমিকা",
    cancel: "বাতিল",
    assign: "বরাদ্দ করুন",
    admin: "অ্যাডমিন",
    manager: "ব্যবস্থাপক",
    sales: "বিক্রয়",
    supplier: "সরবরাহকারী",
    cashier: "ক্যাশিয়ার",
}

export const userRoleLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}