const english = {
    pageTitle: "Transaction Details",
    search: "Search",
    billing: "Billing",
    return : "Return",
    payment: "Payment",
    totalInvoice: "Total Invoice",
    totalReceived: "Total Received",
    totalDue: "Total Due",
    subTotal: "Sub Total",
    discount: "Discount",
    grandTotal: "Grand Total",
    sl: "SL",
    invoiceNo: "Invoice No",
    member: "Member",
    date:"Date",
    status: "Status",
    paymentMode: "Payment Mode",
    action: "Action",
    name: "Name",
    phoneNumber: "Phone Number",
    time: "Time",
    item: "Item",
    qty: "Quantity",
    price: "Price",
    totalPrice: "Total Price",
    vat: "Vat",
    tax : "Tax",
    billTotal: "Bill Total",
    providedBy: "Provided By",
    termsConditions: "Terms & Conditions",
    thankYou : "Thank You",
    technologyPartner: "Technology Partner",
    paid: "Paid",
    unpaid: "Unpaid",
    partiallyPaid: "Partially Paid",
    referenceInvoiceNo: "Reference Invoice No",
    amount: "Amount",
    mrrNo: "MRR No",
    paidAmount: "Paid Amount",
    dueAmount: "Due Amount",
    totalAmount: "Total Amount",
    paymentRequest: "Payment Request",
    remark: "Remark",
    requiredPaymentAmount: "Required Payment Amount",
    helpText : "If you have any question or need help, please contact us.",
    dipositFor: "Deposit For",
    paymentText : "A request has been made for you to submit payment for medical experience of the patient listed below.",
    bdt: "BDT",
    pleaseProvideValidInput: "Please provide valid input.",
    returned: "Returned",
    cash : "Cash",
    creditPayment : "Credit Payment",
    payNow: "Pay Now",

}
const malay = {
    pageTitle: "Butiran Transaksi",
    search: "Cari",
    billing: "Bil",
    return : "Pulangan",
    payment: "Pembayaran",
    totalInvoice: "Jumlah Invois",
    totalReceived: "Jumlah Diterima",
    totalDue: "Jumlah Hutang",
    subTotal: "Jumlah Kecil",
    discount: "Diskaun",
    grandTotal: "Jumlah Besar",
    sl: "No",
    invoiceNo: "No. Invois",
    member: "Ahli",
    date:"Tarikh",
    status: "Status",
    paymentMode: "Mod Pembayaran",
    action: "Tindakan",
    name: "Nama",
    phoneNumber: "Nombor Telefon",
    time: "Masa",
    item: "Item",
    qty: "Kuantiti",
    price: "Harga",
    totalPrice: "Jumlah Harga",
    vat: "Vat",
    tax : "Cukai",
    billTotal: "Jumlah Bil",
    providedBy: "Diberikan Oleh",
    termsConditions: "Terma & Syarat",
    thankYou : "Terima Kasih",
    technologyPartner: "Rakan Teknologi",
    paid: "Dibayar",
    unpaid: "Belum Dibayar",
    partiallyPaid: "Dibayar Sebahagian",
    referenceInvoiceNo: "No. Rujukan Invois",
    amount: "Jumlah",
    mrrNo: "No. MRR",
    paidAmount: "Jumlah Dibayar",
    dueAmount: "Jumlah Hutang",
    totalAmount: "Jumlah Keseluruhan",
    paymentRequest: "Permintaan Pembayaran",
    remark: "Catatan",
    requiredPaymentAmount: "Jumlah Pembayaran Diperlukan",
    helpText : "Jika anda mempunyai sebarang soalan atau memerlukan bantuan, sila hubungi kami.",
    dipositFor: "Deposit Untuk",
    paymentText : "Permintaan telah dibuat untuk anda membuat pembayaran bagi pengalaman perubatan pesakit yang disenaraikan di bawah.",
    currency: "MYR",
    pleaseProvideValidInput: "Sila berikan input yang sah.",
    returned: "Dikembalikan",
    cash : "Tunai",
    creditPayment : "Pembayaran Kredit",
    payNow: "Bayar Sekarang",
};
const thai = {
    pageTitle: "รายละเอียดธุรกรรม",
    search: "ค้นหา",
    billing: "การเรียกเก็บเงิน",
    return : "การคืนสินค้า",
    payment: "การชำระเงิน",
    totalInvoice: "ยอดรวมใบแจ้งหนี้",
    totalReceived: "ยอดรวมที่ได้รับ",
    totalDue: "ยอดรวมที่ค้างชำระ",
    subTotal: "ยอดรวมย่อย",
    discount: "ส่วนลด",
    grandTotal: "ยอดรวมทั้งหมด",
    sl: "ลำดับ",
    invoiceNo: "เลขที่ใบแจ้งหนี้",
    member: "สมาชิก",
    date:"วันที่",
    status: "สถานะ",
    paymentMode: "วิธีการชำระเงิน",
    action: "การกระทำ",
    name: "ชื่อ",
    phoneNumber: "หมายเลขโทรศัพท์",
    time: "เวลา",
    item: "สินค้า",
    qty: "จำนวน",
    price: "ราคา",
    totalPrice: "ราคารวม",
    vat: "ภาษีมูลค่าเพิ่ม",
    tax : "ภาษี",
    billTotal: "ยอดรวมบิล",
    providedBy: "ให้บริการโดย",
    termsConditions: "ข้อกำหนดและเงื่อนไข",
    thankYou : "ขอบคุณ",
    technologyPartner: "พันธมิตรด้านเทคโนโลยี",
    paid: "ชำระแล้ว",
    unpaid: "ยังไม่ได้ชำระ",
    partiallyPaid: "ชำระบางส่วน",
    referenceInvoiceNo: "เลขที่ใบแจ้งหนี้อ้างอิง",
    amount: "จำนวนเงิน",
    mrrNo: "เลขที่ MRR",
    paidAmount: "จำนวนเงินที่ชำระ",
    dueAmount: "จำนวนเงินที่ค้างชำระ",
    totalAmount: "ยอดรวมทั้งหมด",
    paymentRequest: "คำขอชำระเงิน",
    remark: "หมายเหตุ",
    requiredPaymentAmount: "จำนวนเงินที่ต้องชำระ",
    helpText : "หากคุณมีคำถามหรือต้องการความช่วยเหลือ กรุณาติดต่อเรา",
    dipositFor: "เงินฝากสำหรับ",
    paymentText : "มีการขอให้คุณส่งการชำระเงินสำหรับประสบการณ์ทางการแพทย์ของผู้ป่วยที่ระบุไว้ด้านล่าง",
    currency: "THB",
    pleaseProvideValidInput: "กรุณาใส่ข้อมูลที่ถูกต้อง",
    returned : "ส่งคืน",
    cash: "เงินสด",
    creditPayment : "การชำระเงินด้วยบัตรเครดิต",
    payNow: "ชำระเงินตอนนี้",
};
const arabic = {
    pageTitle: "تفاصيل المعاملة",
    search: "بحث",
    billing: "الفوترة",
    return : "إرجاع",
    payment: "دفع",
    totalInvoice: "إجمالي الفاتورة",
    totalReceived: "إجمالي المستلم",
    totalDue: "إجمالي المستحق",
    subTotal: "المجموع الفرعي",
    discount: "خصم",
    grandTotal: "المجموع الإجمالي",
    sl: "الرقم التسلسلي",
    invoiceNo: "رقم الفاتورة",
    member: "عضو",
    date:"تاريخ",
    status: "الحالة",
    paymentMode: "طريقة الدفع",
    action: "الإجراء",
    name: "اسم",
    phoneNumber: "رقم الهاتف",
    time: "الوقت",
    item: "عنصر",
    qty: "الكمية",
    price: "السعر",
    totalPrice: "إجمالي السعر",
    vat: "ضريبة القيمة المضافة",
    tax : "ضريبة",
    billTotal: "إجمالي الفاتورة",
    providedBy: "مقدم من",
    termsConditions: "الأحكام والشروط",
    thankYou : "شكرا لك",
    technologyPartner: "الشريك التكنولوجي",
    paid: "مدفوع",
    unpaid: "غير مدفوع",
    partiallyPaid: "مدفوع جزئيا",
    referenceInvoiceNo: "رقم الفاتورة المرجعية",
    amount: "المبلغ",
    mrrNo: "رقم MRR",
    paidAmount: "المبلغ المدفوع",
    dueAmount: "المبلغ المستحق",
    totalAmount: "المبلغ الإجمالي",
    paymentRequest: "طلب الدفع",
    remark: "ملاحظة",
    requiredPaymentAmount: "المبلغ المطلوب للدفع",
    helpText : "إذا كان لديك أي سؤال أو تحتاج إلى مساعدة، يرجى الاتصال بنا.",
    dipositFor: "وديعة من أجل",
    paymentText : "تم تقديم طلب لك لتقديم الدفع لتجربة طبية للمريض المدرج أدناه.",
    currency: "SAR",
    pleaseProvideValidInput: "يرجى تقديم مدخل صالح.",
    returned: "تم إرجاعه",
    cash: "نقدًا",
    creditPayment : "دفع بالائتمان",
    payNow : "ادفع الآن"
};
const bengali = {
    pageTitle: "লেনদেনের বিবরণ",
    search: "অনুসন্ধান",
    billing: "বিলিং",
    return : "ফেরত",
    payment: "পেমেন্ট",
    totalInvoice: "মোট ইনভয়েস",
    totalReceived: "মোট প্রাপ্ত",
    totalDue: "মোট বাকি",
    subTotal: "উপ মোট",
    discount: "ছাড়",
    grandTotal: "সর্বমোট",
    sl: "ক্রমিক",
    invoiceNo: "ইনভয়েস নং",
    member: "সদস্য",
    date:"তারিখ",
    status: "অবস্থা",
    paymentMode: "পেমেন্ট মোড",
    action: "ক্রিয়া",
    name: "নাম",
    phoneNumber: "ফোন নম্বর",
    time: "সময়",
    item: "আইটেম",
    qty: "পরিমাণ",
    price: "মূল্য",
    totalPrice: "মোট মূল্য",
    vat: "ভ্যাট",
    tax : "কর",
    billTotal: "বিল মোট",
    providedBy: "প্রদত্ত",
    termsConditions: "শর্তাবলী",
    thankYou : "ধন্যবাদ",
    technologyPartner: "প্রযুক্তি অংশীদার",
    paid: "পরিশোধিত",
    unpaid: "অপরিশোধিত",
    partiallyPaid: "আংশিকভাবে পরিশোধিত",
    referenceInvoiceNo: "রেফারেন্স ইনভয়েস নং",
    amount: "পরিমাণ",
    mrrNo: "এমআরআর নং",
    paidAmount: "পরিশোধিত পরিমাণ",
    dueAmount: "বাকি পরিমাণ",
    totalAmount: "মোট পরিমাণ",
    paymentRequest: "পেমেন্ট অনুরোধ",
    remark: "মন্তব্য",
    requiredPaymentAmount: "প্রয়োজনীয় পেমেন্ট পরিমাণ",
    helpText : "যদি আপনার কোন প্রশ্ন থাকে বা সাহায্যের প্রয়োজন হয়, দয়া করে আমাদের সাথে যোগাযোগ করুন।",
    dipositFor: "জমা জন্য",
    paymentText : "রোগীর নীচে তালিকাভুক্ত চিকিত্সা অভিজ্ঞতার জন্য আপনাকে পেমেন্ট জমা দেওয়ার জন্য একটি অনুরোধ করা হয়েছে।",
    currency: "BDT",
    pleaseProvideValidInput: "দয়া করে বৈধ ইনপুট প্রদান করুন।",
    returned : "ফেরত",
    cash : "নগদ",
    creditPayment : "বাকি পরিশোধ",
    payNow : "এখন পরিশোধ করুন",
};

export const transactionDetailsLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}