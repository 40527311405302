const english = {
    pageTitle: "Settings",
    location : "Location",
    language : "Language",
    currency : "Currency",
    timeZone : "Time Zone",
    save : "Save",
    select: "Select",
}
const malay = {
    pageTitle: "Tetapan",
    location: "Lokasi",
    language: "Bahasa",
    currency: "Mata Wang",
    timeZone: "Zon Masa",
    save: "Simpan",
    select: "Pilih",
}
const thai = {
    pageTitle: "การตั้งค่า",
    location: "สถานที่",
    language: "ภาษา",
    currency: "สกุลเงิน",
    timeZone: "เขตเวลา",
    save: "บันทึก",
    select: "เลือก",
}
const arabic = {
    pageTitle: "الإعدادات",
    location: "الموقع",
    language: "اللغة",
    currency: "العملة",
    timeZone: "المنطقة الزمنية",
    save: "حفظ",
    select: "اختر",
}
const bengali = {
    pageTitle: "সেটিংস",
    location: "অবস্থান",
    language: "ভাষা",
    currency: "মুদ্রা",
    timeZone: "সময় অঞ্চল",
    save: "সংরক্ষণ",
    select: "নির্বাচন করুন",
}

export const settingsLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}