const english = {
    pageTitle : "City List",
    addCity : "Add City",
    editCity : "Edit City",
    cityName : "City Name",
    description : "Description",
    action : "Action",
    edit : "Edit",
    delete : "Delete",
    deleteConfirm : "Are you sure?",
    deleteText : "You won't be able to revert this!",
    yes : "Yes, delete it!",
    no : "No",
    cancel : "Cancel",
    city : "City",
    sl : "SL",
    update : "Update",
    save : "Save",
    back : "Back",
    message : "Your data has been deleted.",
    deleted : "Deleted!"
}
const malay = {
    pageTitle: "Senarai Bandar",
    addCity: "Tambah Bandar",
    editCity: "Sunting Bandar",
    cityName: "Nama Bandar",
    description: "Penerangan",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    city: "Bandar",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!"
};
const thai = {
    pageTitle: "รายการเมือง",
    addCity: "เพิ่มเมือง",
    editCity: "แก้ไขเมือง",
    cityName: "ชื่อเมือง",
    description: "คำอธิบาย",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    city: "เมือง",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!"
};
const arabic = {
    pageTitle: "قائمة المدن",
    addCity: "إضافة مدينة",
    editCity: "تعديل المدينة",
    cityName: "اسم المدينة",
    description: "الوصف",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    city: "المدينة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!"
};
const bengali = {
    pageTitle: "শহরের তালিকা",
    addCity: "শহর যোগ করুন",
    editCity: "শহর সম্পাদনা করুন",
    cityName: "শহরের নাম",
    description: "বিবরণ",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    city: "শহর",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!"
};

export const cityLang = {
    english: english,
    malay: malay,
    thai: thai,
    arabic: arabic,
    bengali: bengali
}