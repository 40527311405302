const english = {
    pageTitle : "Group List",
    addGroup : "Add Group",
    editGroup : "Edit Group",
    cityName : "Group Name",
    description : "Description",
    action : "Action",
    edit : "Edit",
    delete : "Delete",
    deleteConfirm : "Are you sure?",
    deleteText : "You won't be able to revert this!",
    yes : "Yes, delete it!",
    no : "No",
    cancel : "Cancel",
    group : "Group",
    sl : "SL",
    update : "Update",
    save : "Save",
    back : "Back",
    message : "Your data has been deleted.",
    deleted : "Deleted!"
}
const malay = {
    pageTitle: "Senarai Kumpulan",
    addGroup: "Tambah Kumpulan",
    editGroup: "Sunting Kumpulan",
    groupName: "Nama Kumpulan",
    description: "Deskripsi",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    group: "Kumpulan",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!"
};
const thai = {
    pageTitle: "รายการกลุ่ม",
    addGroup: "เพิ่มกลุ่ม",
    editGroup: "แก้ไขกลุ่ม",
    groupName: "ชื่อกลุ่ม",
    description: "คำอธิบาย",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    group: "กลุ่ม",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!"
};
const arabic = {
    pageTitle: "قائمة المجموعات",
    addGroup: "إضافة مجموعة",
    editGroup: "تعديل مجموعة",
    groupName: "اسم المجموعة",
    description: "الوصف",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    group: "مجموعة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!"
};
const bengali = {
    pageTitle: "গ্রুপ তালিকা",
    addGroup: "গ্রুপ যোগ করুন",
    editGroup: "গ্রুপ সম্পাদনা করুন",
    groupName: "গ্রুপের নাম",
    description: "বর্ণনা",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    group: "গ্রুপ",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!"
};

export const groupLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}