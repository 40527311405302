const english = {
    clear: "Clear",
    allInvoice: "All Invoice",
    documentUpload: "Document Upload",
    save: "Save",
    cancel: "Cancel",
    take: "Take",
    upload: "Upload",
    productName: "Product Name",
    expDate: "Exp. Date",
    boxType: "Box Type",
    size: "Size",
    boxLeaf: "Box/Leaf",
    quantity: "Quantity",
    discount: "Discount",
    price: "Price",
    total: "Total",
    action: "Action",
    subTotal: "Sub Total",
    payNow: "Pay Now",
    counter: "Counter",
    paymentMethod: "Payment Method",
    cash: "Cash",
    credit: "Credit/Debit Cart",
    invoicePreview: "Invoice Preview",
    fixed: "Fixed",
    percentage: "Percentage",
    vat: "VAT",
    tax: "TAX",
    vatTax: "VAT/TAX",
    grandTotal: "Grand Total",
    paidAmount: "Paid Amount",
    dueAmount: "Due Amount",
    returnAmount: "Return Amount",
    payFirst: "Pay first",
    preview: "Preview",
    cashCollection: "Cash Collection",
    creditPayment: "Credit Payment",
    payment: "Payment",
    cashMemo: "Cash Memo",
    salesPerson: "Sales Person",
    invoiceNo: "Invoice No",
    date: "Date",
    item: "Item",
    totalPrice: "Total Price",
    specialDiscount: "Special Discount",
    billTotal: "Bill Total",
    providedBy: "Provided By",
    termsCondition: "Terms & Condition",
    technologyPartner: "Technology Partner",
    thankYou: "Thank You",
    time: "Time",
    paid: "Paid",
    unpaid: "Unpaid",
    due: "Due",
    pending: "Pending",
    paymentDone: "Payment Done",
    return: "Return",
    unit: "Unit",
    warning: "Are you sure?",
    yes: "Yes return it!",
    youWontRevertIt: "You won't be able to revert this!",
    returnInvoiceCanceled: "Return Invoice Canceled",
    membership: "Membership",
    help: "Help",
    support: "Support",
    legal: "Legal",
    copyright: "Copyright",
    allRightsReserve: "All Rights Reserved",
    developedBy: "Developed By",
    logOut: "Log Out",
    searchProducts: "Search Products",
    select: "Select",
    userRole: "User Role",
    memberName: "Member Name",
    phoneNumber: "Phone Number",
    all: "All",
    searchInvoice: "Search Invoice",
    status: "Status",
    name: "Name",
    phone: "Phone",
    email: "Email",
    submit: "Submit",
    newMembershipForm: "New Membership Form",
    searchMember : "Search Member",
    search : "Search",
    partiallyPaid : "Partially Paid",

}
const malay = {
    clear: "Kosongkan",
    allInvoice: "Semua Invois",
    documentUpload: "Muat Naik Dokumen",
    save: "Simpan",
    cancel: "Batal",
    take: "Ambil",
    upload: "Muat Naik",
    productName: "Nama Produk",
    expDate: "Tarikh Luput",
    boxType: "Jenis Kotak",
    size: "Saiz",
    boxLeaf: "Kotak/Leaf",
    quantity: "Kuantiti",
    discount: "Diskaun",
    price: "Harga",
    total: "Jumlah",
    action: "Tindakan",
    subTotal: "Jumlah Sub",
    payNow: "Bayar Sekarang",
    counter: "Kaunter",
    paymentMethod: "Kaedah Pembayaran",
    cash: "Tunai",
    credit: "Kad Kredit/Debit",
    invoicePreview: "Pratonton Invois",
    fixed: "Tetap",
    percentage: "Peratus",
    vat: "VAT",
    tax: "Cukai",
    vatTax: "VAT/Cukai",
    grandTotal: "Jumlah Keseluruhan",
    paidAmount: "Jumlah Dibayar",
    dueAmount: "Jumlah Berhutang",
    returnAmount: "Jumlah Pulangan",
    payFirst: "Bayar dahulu",
    preview: "Pratonton",
    cashCollection: "Kutipan Tunai",
    creditPayment: "Pembayaran Kredit",
    payment: "Pembayaran",
    cashMemo: "Memo Tunai",
    salesPerson: "Jurujual",
    invoiceNo: "No. Invois",
    date: "Tarikh",
    item: "Item",
    totalPrice: "Jumlah Harga",
    specialDiscount: "Diskaun Khas",
    billTotal: "Jumlah Bil",
    providedBy: "Disediakan Oleh",
    termsCondition: "Terma & Syarat",
    technologyPartner: "Rakan Teknologi",
    thankYou: "Terima Kasih",
    time: "Masa",
    paid: "Dibayar",
    unpaid: "Belum Dibayar",
    due: "Berhutang",
    pending: "Belum Selesai",
    paymentDone: "Pembayaran Selesai",
    return: "Pulangan",
    unit: "Unit",
    warning: "Adakah anda pasti?",
    yes: "Ya, pulangkan!",
    youWontRevertIt: "Anda tidak boleh mengembalikkan ini!",
    returnInvoiceCanceled: "Invois Pulangan Dibatalkan",
    membership: "Keahlian",
    help: "Bantuan",
    support: "Sokongan",
    legal: "Undang-Undang",
    copyright: "Hak Cipta",
    allRightsReserve: "Semua Hak Cipta Terpelihara",
    developedBy: "Dikembangkan Oleh",
    logOut: "Log Keluar",
    searchProducts: "Cari Produk",
    select: "Pilih",
    userRole: "Peranan Pengguna",
    memberName: "Nama Ahli",
    phoneNumber: "Nombor Telefon",
    all: "Semua",
    searchInvoice: "Cari Invois",
    status: "Status",
    name: "Nama",
    phone: "Telefon",
    email: "Emel",
    submit: "Hantar",
    newMembershipForm: "Borang Keahlian Baru",
    searchMember: "Cari Ahli",
    search: "Cari",
    partiallyPaid: "Sebahagian Dibayar",
};

const thai = {
    clear: "เคลียร์",
    allInvoice: "ใบแจ้งหนี้ทั้งหมด",
    documentUpload: "อัปโหลดเอกสาร",
    save: "บันทึก",
    cancel: "ยกเลิก",
    take: "เก็บ",
    upload: "อัปโหลด",
    productName: "ชื่อสินค้า",
    expDate: "วันที่หมดอายุ",
    boxType: "ประเภทกล่อง",
    size: "ขนาด",
    boxLeaf: "กล่อง/ใบ",
    quantity: "จำนวน",
    discount: "ส่วนลด",
    price: "ราคา",
    total: "รวม",
    action: "การกระทำ",
    subTotal: "ยอดรวมย่อย",
    payNow: "ชำระเงินทันที",
    counter: "เคาน์เตอร์",
    paymentMethod: "วิธีการชำระเงิน",
    cash: "เงินสด",
    credit: "บัตรเครดิต/เดบิต",
    invoicePreview: "ดูตัวอย่างใบแจ้งหนี้",
    fixed: "คงที่",
    percentage: "เปอร์เซ็นต์",
    vat: "ภาษีมูลค่าเพิ่ม",
    tax: "ภาษี",
    vatTax: "ภาษีมูลค่าเพิ่ม/ภาษี",
    grandTotal: "ยอดรวมทั้งหมด",
    paidAmount: "จำนวนเงินที่จ่าย",
    dueAmount: "จำนวนเงินค้างชำระ",
    returnAmount: "จำนวนเงินทอน",
    payFirst: "ชำระก่อน",
    preview: "ดูตัวอย่าง",
    cashCollection: "การรวบรวมเงินสด",
    creditPayment: "การชำระเงินเครดิต",
    payment: "การชำระเงิน",
    cashMemo: "ใบสั่งจ่ายเงินสด",
    salesPerson: "พนักงานขาย",
    invoiceNo: "หมายเลขใบแจ้งหนี้",
    date: "วันที่",
    item: "สินค้า",
    totalPrice: "ราคารวม",
    specialDiscount: "ส่วนลดพิเศษ",
    billTotal: "ยอดค่าใช้จ่ายทั้งหมด",
    providedBy: "ให้บริการโดย",
    termsCondition: "เงื่อนไข",
    technologyPartner: "พันธมิตรทางเทคโนโลยี",
    thankYou: "ขอบคุณ",
    time: "เวลา",
    paid: "จ่ายแล้ว",
    unpaid: "ยังไม่ได้ชำระ",
    due: "ค้างชำระ",
    pending: "รอดำเนินการ",
    paymentDone: "ชำระเงินเสร็จสิ้น",
    return: "การคืน",
    unit: "หน่วย",
    warning: "คุณแน่ใจหรือไม่?",
    yes: "ใช่ คืนสินค้า!",
    youWontRevertIt: "คุณจะไม่สามารถเรียกคืนได้!",
    returnInvoiceCanceled: "ยกเลิกใบแจ้งหนี้คืน",
    membership: "สมาชิก",
    help: "ช่วยเหลือ",
    support: "สนับสนุน",
    legal: "กฎหมาย",
    copyright: "ลิขสิทธิ์",
    allRightsReserve: "สงวนลิขสิทธิ์ทั้งหมด",
    developedBy: "พัฒนาโดย",
    logOut: "ออกจากระบบ",
    searchProducts: "ค้นหาสินค้า",
    select: "เลือก",
    userRole: "บทบาทผู้ใช้",
    memberName: "ชื่อสมาชิก",
    phoneNumber: "หมายเลขโทรศัพท์",
    all: "ทั้งหมด",
    searchInvoice: "ค้นหาใบแจ้งหนี้",
    status: "สถานะ",
    name: "ชื่อ",
    phone: "โทรศัพท์",
    email: "อีเมล",
    submit: "ส่ง",
    newMembershipForm: "แบบฟอร์มสมาชิกใหม่",
    searchMember: "ค้นหาสมาชิก",
    search: "ค้นหา",
    partiallyPaid: "ชำระบางส่วน",

};

const arabic = {
    clear: "مسح",
    allInvoice: "كل الفواتير",
    documentUpload: "تحميل المستندات",
    save: "حفظ",
    cancel: "إلغاء",
    take: "أخذ",
    upload: "رفع",
    productName: "اسم المنتج",
    expDate: "تاريخ الانتهاء",
    boxType: "نوع الصندوق",
    size: "الحجم",
    boxLeaf: "صندوق/ورقة",
    quantity: "الكمية",
    discount: "خصم",
    price: "السعر",
    total: "المجموع",
    action: "إجراء",
    subTotal: "المجموع الفرعي",
    payNow: "الدفع الآن",
    counter: "عداد",
    paymentMethod: "طريقة الدفع",
    cash: "نقداً",
    credit: "بطاقة ائتمان/خصم",
    invoicePreview: "معاينة الفاتورة",
    fixed: "ثابت",
    percentage: "النسبة المئوية",
    vat: "ضريبة القيمة المضافة",
    tax: "الضريبة",
    vatTax: "ضريبة القيمة المضافة/الضريبة",
    grandTotal: "المجموع الكلي",
    paidAmount: "المبلغ المدفوع",
    dueAmount: "المبلغ المستحق",
    returnAmount: "مبلغ الإرجاع",
    payFirst: "الدفع أولاً",
    preview: "معاينة",
    cashCollection: "تحصيل النقدية",
    creditPayment: "الدفع بالبطاقة الائتمانية",
    payment: "الدفع",
    cashMemo: "مذكرة نقدية",
    salesPerson: "المندوب التجاري",
    invoiceNo: "رقم الفاتورة",
    date: "التاريخ",
    item: "البند",
    totalPrice: "السعر الكلي",
    specialDiscount: "خصم خاص",
    billTotal: "مجموع الفاتورة",
    providedBy: "المقدم من",
    termsCondition: "الشروط والأحكام",
    technologyPartner: "الشريك التقني",
    thankYou: "شكراً لك",
    time: "الوقت",
    paid: "تم الدفع",
    unpaid: "لم يتم الدفع",
    due: "مستحق",
    pending: "قيد الانتظار",
    paymentDone: "تم الدفع",
    return: "الإرجاع",
    unit: "الوحدة",
    warning: "هل أنت متأكد؟",
    yes: "نعم، أرجعها!",
    youWontRevertIt: "لن تتمكن من التراجع عن ذلك!",
    returnInvoiceCanceled: "تم إلغاء إرجاع الفاتورة",
    membership: "العضوية",
    help: "مساعدة",
    support: "الدعم",
    legal: "قانوني",
    copyright: "حقوق النشر",
    allRightsReserve: "جميع الحقوق محفوظة",
    developedBy: "تطوير من قبل",
    logOut: "تسجيل الخروج",
    searchProducts: "البحث عن المنتجات",
    select: "اختيار",
    userRole: "دور المستخدم",
    memberName: "اسم العضو",
    phoneNumber: "رقم الهاتف",
    all: "الكل",
    searchInvoice: "البحث عن الفاتورة",
    status: "الحالة",
    name: "الاسم",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    submit: "إرسال",
    newMembershipForm: "نموذج عضوية جديدة",
    searchMember: "البحث عن عضو",
    search: "بحث",
    partiallyPaid : "مدفوع جزئياً",
};

const bengali = {
    clear: "পরিষ্কার করুন",
    allInvoice: "সমস্ত চালান",
    documentUpload: "ডকুমেন্ট আপলোড",
    save: "সংরক্ষণ করুন",
    cancel: "বাতিল করুন",
    take: "নিন",
    upload: "আপলোড করুন",
    productName: "পণ্যের নাম",
    expDate: "মেয়াদ উত্তীর্ণ হওয়ার তারিখ",
    boxType: "বাক্সের ধরণ",
    size: "আকার",
    boxLeaf: "বক্স/পাতা",
    quantity: "পরিমাণ",
    discount: "মূল্যছাড়",
    price: "মূল্য",
    total: "মোট",
    action: "কর্ম",
    subTotal: "উপ-মোট",
    payNow: "এখনই পরিশোধ করুন",
    counter: "কাউন্টার",
    paymentMethod: "পেমেন্ট পদ্ধতি",
    cash: "নগদ",
    credit: "ক্রেডিট/ডেবিট কার্ড",
    invoicePreview: "চালানের পূর্বরূপ",
    fixed: "স্থির",
    percentage: "শতাংশ",
    vat: "ভ্যাট",
    tax: "কর",
    vatTax: "ভ্যাট/কর",
    grandTotal: "সর্বমোট",
    paidAmount: "পরিশোধিত পরিমাণ",
    dueAmount: "বকেয়া পরিমাণ",
    returnAmount: "ফেরত প্রদানের পরিমাণ",
    payFirst: "প্রথমে পরিশোধ করুন",
    preview: "পূর্বরূপ",
    cashCollection: "নগদ সংগ্রহ",
    creditPayment: "ক্রেডিট পেমেন্ট",
    payment: "পরিশোধ",
    cashMemo: "নগদ মেমো",
    salesPerson: "বিক্রয় ব্যক্তি",
    invoiceNo: "চালান নম্বর",
    date: "তারিখ",
    item: "আইটেম",
    totalPrice: "মোট মূল্য",
    specialDiscount: "বিশেষ ডিসকাউন্ট",
    billTotal: "বিল মোট",
    providedBy: "দ্বারা সরবরাহিত",
    termsCondition: "শর্তাদি",
    technologyPartner: "প্রযুক্তি অংশীদার",
    thankYou: "ধন্যবাদ",
    time: "সময়",
    paid: "পরিশোধিত",
    unpaid: "অপরিশোধিত",
    due: "বকেয়া",
    pending: "মুলতুবি",
    paymentDone: "পরিশোধ সম্পন্ন",
    return: "ফেরত",
    unit: "ইউনিট",
    warning: "আপনি কি নিশ্চিত?",
    yes: "হ্যাঁ, ফেরত দিন!",
    youWontRevertIt: "আপনি এটি ফিরিয়ে আনতে পারবেন না!",
    returnInvoiceCanceled: "ফেরত চালান বাতিল হয়েছে",
    membership: "সদস্যপদ",
    help: "সাহায্য",
    support: "সমর্থন",
    legal: "আইনি",
    copyright: "কপিরাইট",
    allRightsReserve: "সমস্ত অধিকার সংরক্ষিত",
    developedBy: "উন্নত করেছে",
    logOut: "প্রস্থান",
    searchProducts: "পণ্য অনুসন্ধান",
    select: "নির্বাচন",
    userRole: "ব্যবহারকারীর ভূমিকা",
    memberName: "সদস্যের নাম",
    phoneNumber: "ফোন নম্বর",
    all: "সব",
    searchInvoice: "চালান অনুসন্ধান",
    status: "অবস্থা",
    name: "নাম",
    phone: "ফোন",
    email: "ইমেইল",
    submit: "জমা দিন",
    newMembershipForm: "নতুন সদস্যপদ ফর্ম",
    searchMember: "সদস্য অনুসন্ধান",
    search: "অনুসন্ধান",
    partiallyPaid : "আংশিকভাবে পরিশোধিত",
};


export const cashierLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}