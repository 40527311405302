const english = {
    pageTitle: "Purchase In List",
    addPurchaseIn : "Add Purchase In",
    sl: "SL",
    purchaseInNo : "Purchase In No",
    deliveryDate : "Delivery Date",
    totalCost : "Total Cost",
    contactNo: "Contact No",
    status: "Status",
    action: "Action",
    purchaseIn: "Purchase In",
    referenceInvoiceNo: "Reference Invoice No",
    referenceOrderNo: "Reference Order No",
    orderBy: "Order By",
    supplier: "Supplier",
    selectBrand: "Select Brand",
    searchMedicine: "Search Medicine",
    productDetails: "Product Details",
    name: "Name",
    quantity: "Quantity",
    pktSize: "Pkt Size",
    noOfBoxBottle : "No. of Box/Bottle",
    unit: "Unit",
    pp: "PP",
    bonusQty : "Bonus Qty (Pcs)",
    tp: "TP",
    totalPrice: "Total Price",
    vat:"Vat",
    discount:"Discount",
    vatAmount : "Vat Amount",
    cancel: "Cancel",
    save: "Save",
    discountType : "Discount Type",
    fixed: "Fixed",
    percentage: "Percentage",
    discountAmount: "Discount Amount",
    payFirst: "Pay First",
    productWiseVatDiscount : "Product Wise Vat & Discount",
    vatPercentage : "Vat Percentage",
    payment: "Payment",
    cash: "Cash",
    creditDebitCard: "Credit/Debit Card",
    due: "Due",
    grandTotal : "Grand Total",
    paid: "Paid",
    back: "Back",
    approved: "Approved",
    pending: "Pending",
    remarks : "Remarks",
    subTotal : "Sub Total",

}
const malay = {
    pageTitle: "Senarai Pembelian Masuk",
    addPurchaseIn: "Tambah Pembelian Masuk",
    sl: "No",
    purchaseInNo: "No Pembelian Masuk",
    deliveryDate: "Tarikh Penghantaran",
    totalCost: "Jumlah Kos",
    contactNo: "No Kontak",
    status: "Status",
    action: "Tindakan",
    purchaseIn: "Pembelian Masuk",
    referenceInvoiceNo: "No Invois Rujukan",
    referenceOrderNo: "No Pesanan Rujukan",
    orderBy: "Dipesan Oleh",
    supplier: "Pembekal",
    selectBrand: "Pilih Jenama",
    searchMedicine: "Cari Ubat",
    productDetails: "Butiran Produk",
    name: "Nama",
    quantity: "Kuantiti",
    pktSize: "Saiz Pkt",
    noOfBoxBottle: "Jumlah Kotak/Botol",
    unit: "Unit",
    pp: "PP",
    bonusQty: "Kuantiti Bonus (Keping)",
    tp: "TP",
    totalPrice: "Jumlah Harga",
    vat: "VAT",
    discount: "Diskaun",
    vatAmount: "Jumlah VAT",
    cancel: "Batal",
    save: "Simpan",
    discountType: "Jenis Diskaun",
    fixed: "Tetap",
    percentage: "Peratusan",
    discountAmount: "Jumlah Diskaun",
    payFirst: "Bayar Dulu",
    productWiseVatDiscount: "VAT & Diskaun Berdasarkan Produk",
    vatPercentage: "Peratusan VAT",
    payment: "Pembayaran",
    cash: "Tunai",
    creditDebitCard: "Kad Kredit/Debit",
    due: "Tertunggak",
    grandTotal: "Jumlah Besar",
    paid: "Dibayar",
    back: "Kembali",
    approved: "Diluluskan",
    pending: "Tertunda",
    remarks: "Ulasan",
    subTotal: "Jumlah Kecil",
};
const thai = {
    pageTitle: "รายการสั่งซื้อ",
    addPurchaseIn: "เพิ่มการสั่งซื้อ",
    sl: "ลำดับ",
    purchaseInNo: "เลขที่การสั่งซื้อ",
    deliveryDate: "วันที่จัดส่ง",
    totalCost: "ต้นทุนรวม",
    contactNo: "หมายเลขติดต่อ",
    status: "สถานะ",
    action: "การดำเนินการ",
    purchaseIn: "สั่งซื้อ",
    referenceInvoiceNo: "เลขที่ใบแจ้งหนี้อ้างอิง",
    referenceOrderNo: "เลขที่ใบสั่งซื้ออ้างอิง",
    orderBy: "สั่งโดย",
    supplier: "ผู้จัดหา",
    selectBrand: "เลือกแบรนด์",
    searchMedicine: "ค้นหายา",
    productDetails: "รายละเอียดสินค้า",
    name: "ชื่อ",
    quantity: "จำนวน",
    pktSize: "ขนาดแพ็ค",
    noOfBoxBottle: "จำนวนกล่อง/ขวด",
    unit: "หน่วย",
    pp: "PP",
    bonusQty: "จำนวนโบนัส (ชิ้น)",
    tp: "TP",
    totalPrice: "ราคารวม",
    vat: "ภาษีมูลค่าเพิ่ม",
    discount: "ส่วนลด",
    vatAmount: "จำนวนภาษีมูลค่าเพิ่ม",
    cancel: "ยกเลิก",
    save: "บันทึก",
    discountType: "ประเภทส่วนลด",
    fixed: "คงที่",
    percentage: "เปอร์เซ็นต์",
    discountAmount: "จำนวนส่วนลด",
    payFirst: "จ่ายก่อน",
    productWiseVatDiscount: "ส่วนลด & ภาษีตามสินค้า",
    vatPercentage: "เปอร์เซ็นต์ภาษีมูลค่าเพิ่ม",
    payment: "การชำระเงิน",
    cash: "เงินสด",
    creditDebitCard: "บัตรเครดิต/เดบิต",
    due: "ค้างชำระ",
    grandTotal: "ยอดรวม",
    paid: "ชำระเงินแล้ว",
    back: "กลับ",
    approved: "อนุมัติ",
    pending: "รอดำเนินการ",
    remarks: "หมายเหตุ",
    subTotal: "ยอดรวมย่อย",
};
const arabic = {
    pageTitle: "قائمة المشتريات",
    addPurchaseIn: "إضافة شراء",
    sl: "الرقم التسلسلي",
    purchaseInNo: "رقم الشراء",
    deliveryDate: "تاريخ التسليم",
    totalCost: "التكلفة الإجمالية",
    contactNo: "رقم الاتصال",
    status: "الحالة",
    action: "الإجراء",
    purchaseIn: "شراء",
    referenceInvoiceNo: "رقم الفاتورة المرجعية",
    referenceOrderNo: "رقم الطلب المرجعي",
    orderBy: "الطلب بواسطة",
    supplier: "المورد",
    selectBrand: "اختر العلامة التجارية",
    searchMedicine: "ابحث عن الدواء",
    productDetails: "تفاصيل المنتج",
    name: "الاسم",
    quantity: "الكمية",
    pktSize: "حجم العبوة",
    noOfBoxBottle: "عدد الصناديق/الزجاجات",
    unit: "الوحدة",
    pp: "PP",
    bonusQty: "الكمية الإضافية (قطع)",
    tp: "TP",
    totalPrice: "السعر الإجمالي",
    vat: "ضريبة القيمة المضافة",
    discount: "الخصم",
    vatAmount: "مبلغ الضريبة",
    cancel: "إلغاء",
    save: "حفظ",
    discountType: "نوع الخصم",
    fixed: "ثابت",
    percentage: "نسبة مئوية",
    discountAmount: "مبلغ الخصم",
    payFirst: "ادفع أولاً",
    productWiseVatDiscount: "ضريبة القيمة المضافة والخصم لكل منتج",
    vatPercentage: "نسبة ضريبة القيمة المضافة",
    payment: "الدفع",
    cash: "نقداً",
    creditDebitCard: "بطاقة ائتمان/خصم",
    due: "مستحق",
    grandTotal: "المجموع الكلي",
    paid: "مدفوع",
    back: "رجوع",
    approved: "موافق عليه",
    pending: "قيد الانتظار",
    remarks: "ملاحظات",
    subTotal: "المجموع الفرعي",
};
const bengali = {
    pageTitle: "ক্রয় তালিকা",
    addPurchaseIn: "ক্রয় যোগ করুন",
    sl: "ক্রমিক",
    purchaseInNo: "ক্রয় নম্বর",
    deliveryDate: "বিতরণের তারিখ",
    totalCost: "মোট খরচ",
    contactNo: "যোগাযোগ নম্বর",
    status: "অবস্থা",
    action: "কর্ম",
    purchaseIn: "ক্রয়",
    referenceInvoiceNo: "রেফারেন্স ইনভয়েস নম্বর",
    referenceOrderNo: "রেফারেন্স অর্ডার নম্বর",
    orderBy: "অর্ডার দ্বারা",
    supplier: "সরবরাহকারী",
    selectBrand: "ব্র্যান্ড নির্বাচন করুন",
    searchMedicine: "ওষুধ খুঁজুন",
    productDetails: "পণ্য বিবরণ",
    name: "নাম",
    quantity: "পরিমাণ",
    pktSize: "প্যাকেট সাইজ",
    noOfBoxBottle: "বক্স/বোতলের সংখ্যা",
    unit: "ইউনিট",
    pp: "পিপি",
    bonusQty: "বোনাস পরিমাণ (পিস)",
    tp: "টিপি",
    totalPrice: "মোট মূল্য",
    vat: "ভ্যাট",
    discount: "ছাড়",
    vatAmount: "ভ্যাট পরিমাণ",
    cancel: "বাতিল",
    save: "সংরক্ষণ",
    discountType: "ছাড়ের ধরন",
    fixed: "স্থায়ী",
    percentage: "শতাংশ",
    discountAmount: "ছাড়ের পরিমাণ",
    payFirst: "প্রথমে পরিশোধ",
    productWiseVatDiscount: "পণ্য ভিত্তিক ভ্যাট ও ছাড়",
    vatPercentage: "ভ্যাট শতাংশ",
    payment: "পেমেন্ট",
    cash: "নগদ",
    creditDebitCard: "ক্রেডিট/ডেবিট কার্ড",
    due: "বকেয়া",
    grandTotal: "মোট সর্বমোট",
    paid: "পরিশোধিত",
    back: "ফিরে যান",
    approved: "অনুমোদিত",
    pending: "অপেক্ষমান",
    remarks: "মন্তব্য",
    subTotal: "মোট",
};

export const purchaseInLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}