const english = {
    dailySalesReport : "Daily Sales Report",
    startDate : "Start Date",
    endDate : "End Date",
    search: "Search",
    sl: "SL",
    invoiceNo: "Invoice No",
    date: "Date",
    member: "Member",
    qty: "Quantity",
    price: "Price",
    subTotal: "Sub Total",
    totalAmount : "Total Amount",
    discount : "Discount",
    totalPrice: "Total Price",
    grandTotal: "Grand Total",
    discountTotal : "Discount Total",
    print: "Print",
    salesSummaryReport: "Sales Summary Report",
    salesReturnReport: "Sales Return Report",
    referenceInvoice: "Reference Invoice",
    product: "Product",
    memberWiseSalesReport: "Member Wise Sales Report",
    return : "Return",
    salesTotal : "Sales Total",
    salesReturnTotal : "Sales Return Total",
    sales: "Sales",
    creditSalesReport: "Credit Sales Report",
    collectionTotal : "Collection Total",
    dueAmount: "Due Amount",
    memberWiseCreditSalesReport: "Member Wise Credit Sales Report",
    purchaseReport: "Purchase Report",
    noOfBox: "No. of Box",
    purchaseAmount : "Purchase Amount",
    totalPurchaseAmount : "Total Purchase Amount",
    vatTotal: "VAT Total",
    purchaseSummaryReport: "Purchase Summary Report",
    supplier: "Supplier",
    purchasePrice: "Purchase Price",
    purchaseType: "Purchase Type",
    purchaseReturnReport: "Purchase Return Report",
    returnQty: "Return Quantity",
    returnAmount: "Return Amount",
    supplierDueReport: "Supplier Due Report",
    stockClosingReport: "Stock Closing Report",
    totalStockAmount: "Total Stock Amount",
    stock: "Stock",
    stockAdjustmentReport: "Stock Adjustment Report",
    inOut: "In/Out",
    damageAmount: "Damage Amount",
    stockExpiryReport: "Stock Expiry Report",
    company: "Company",
    expiryDate: "Expiry Date",
    profitLossReport: "Profit/Loss Report",
    totalSalesAmount: "Total Sales Amount",
    totalDamageAmount: "Total Damage Amount",
    grossProfitLoss: "Gross Profit/Loss",
    paymentMethod : "Payment Method",
    vat : "VAT",
    paidAmount : "Paid Amount",

}
const malay = {
    dailySalesReport: "Laporan Jualan Harian",
    startDate: "Tarikh Mula",
    endDate: "Tarikh Tamat",
    search: "Cari",
    sl: "SL",
    invoiceNo: "No. Invois",
    date: "Tarikh",
    member: "Ahli",
    qty: "Kuantiti",
    price: "Harga",
    subTotal: "Jumlah Kecil",
    totalAmount: "Jumlah Keseluruhan",
    discount: "Diskaun",
    totalPrice: "Jumlah Harga",
    grandTotal: "Jumlah Keseluruhan",
    discountTotal: "Jumlah Diskaun",
    print: "Cetak",
    salesSummaryReport: "Laporan Ringkasan Jualan",
    salesReturnReport: "Laporan Pemulangan Jualan",
    referenceInvoice: "Invois Rujukan",
    product: "Produk",
    memberWiseSalesReport: "Laporan Jualan Mengikut Ahli",
    return: "Pulangan",
    salesTotal: "Jumlah Jualan",
    salesReturnTotal: "Jumlah Pulangan Jualan",
    sales: "Jualan",
    creditSalesReport: "Laporan Jualan Kredit",
    collectionTotal: "Jumlah Kutipan",
    dueAmount: "Jumlah Hutang",
    memberWiseCreditSalesReport: "Laporan Jualan Kredit Mengikut Ahli",
    purchaseReport: "Laporan Pembelian",
    noOfBox: "Bilangan Kotak",
    purchaseAmount: "Jumlah Pembelian",
    totalPurchaseAmount: "Jumlah Keseluruhan Pembelian",
    vatTotal: "Jumlah VAT",
    purchaseSummaryReport: "Laporan Ringkasan Pembelian",
    supplier: "Pembekal",
    purchasePrice: "Harga Pembelian",
    purchaseType: "Jenis Pembelian",
    purchaseReturnReport: "Laporan Pemulangan Pembelian",
    returnQty: "Kuantiti Pulangan",
    returnAmount: "Jumlah Pulangan",
    supplierDueReport: "Laporan Hutang Pembekal",
    stockClosingReport: "Laporan Penutupan Stok",
    totalStockAmount: "Jumlah Keseluruhan Stok",
    stock: "Stok",
    stockAdjustmentReport: "Laporan Pelarasan Stok",
    inOut: "Masuk/Keluar",
    damageAmount: "Jumlah Kerugian",
    stockExpiryReport: "Laporan Tarikh Tamat Tempoh Stok",
    company: "Syarikat",
    expiryDate: "Tarikh Tamat Tempoh",
    profitLossReport: "Laporan Untung/Rugi",
    totalSalesAmount: "Jumlah Keseluruhan Jualan",
    totalDamageAmount: "Jumlah Keseluruhan Kerugian",
    grossProfitLoss: "Untung/Rugi Kasar",
    paymentMethod : "Kaedah Pembayaran",
    vat : "Cukai Nilai Tambah",
    paidAmount : "Jumlah Dibayar",
}
const thai = {
    dailySalesReport: "รายงานการขายรายวัน",
    startDate: "วันที่เริ่มต้น",
    endDate: "วันที่สิ้นสุด",
    search: "ค้นหา",
    sl: "ลำดับ",
    invoiceNo: "เลขที่ใบแจ้งหนี้",
    date: "วันที่",
    member: "สมาชิก",
    qty: "จำนวน",
    price: "ราคา",
    subTotal: "ยอดรวมย่อย",
    totalAmount: "จำนวนทั้งหมด",
    discount: "ส่วนลด",
    totalPrice: "ราคารวม",
    grandTotal: "ยอดรวมทั้งสิ้น",
    discountTotal: "ยอดรวมส่วนลด",
    print: "พิมพ์",
    salesSummaryReport: "รายงานสรุปการขาย",
    salesReturnReport: "รายงานการคืนสินค้า",
    referenceInvoice: "ใบแจ้งหนี้อ้างอิง",
    product: "สินค้า",
    memberWiseSalesReport: "รายงานการขายตามสมาชิก",
    return: "คืนสินค้า",
    salesTotal: "ยอดขายทั้งหมด",
    salesReturnTotal: "ยอดคืนสินค้าทั้งหมด",
    sales: "การขาย",
    creditSalesReport: "รายงานการขายเครดิต",
    collectionTotal: "ยอดรวมการเก็บเงิน",
    dueAmount: "ยอดค้างชำระ",
    memberWiseCreditSalesReport: "รายงานการขายเครดิตตามสมาชิก",
    purchaseReport: "รายงานการซื้อ",
    noOfBox: "จำนวนกล่อง",
    purchaseAmount: "ยอดซื้อ",
    totalPurchaseAmount: "ยอดซื้อทั้งหมด",
    vatTotal: "ยอดรวม VAT",
    purchaseSummaryReport: "รายงานสรุปการซื้อ",
    supplier: "ผู้จัดจำหน่าย",
    purchasePrice: "ราคาซื้อ",
    purchaseType: "ประเภทการซื้อ",
    purchaseReturnReport: "รายงานการคืนสินค้า",
    returnQty: "จำนวนการคืน",
    returnAmount: "ยอดคืนสินค้า",
    supplierDueReport: "รายงานยอดค้างชำระผู้จัดจำหน่าย",
    stockClosingReport: "รายงานปิดยอดสต็อก",
    totalStockAmount: "จำนวนสต็อกรวม",
    stock: "สต็อก",
    stockAdjustmentReport: "รายงานการปรับสต็อก",
    inOut: "เข้า/ออก",
    damageAmount: "จำนวนความเสียหาย",
    stockExpiryReport: "รายงานวันหมดอายุของสต็อก",
    company: "บริษัท",
    expiryDate: "วันหมดอายุ",
    profitLossReport: "รายงานกำไร/ขาดทุน",
    totalSalesAmount: "ยอดขายทั้งหมด",
    totalDamageAmount: "ยอดความเสียหายทั้งหมด",
    grossProfitLoss: "กำไร/ขาดทุนขั้นต้น",
    paymentMethod: "วิธีการชำระเงิน",
    vat : "ภาษีมูลค่าเพิ่ม",
    paidAmount : "จำนวนเงินที่จ่าย",

}
const arabic = {
    dailySalesReport: "تقرير المبيعات اليومية",
    startDate: "تاريخ البدء",
    endDate: "تاريخ الانتهاء",
    search: "بحث",
    sl: "الرقم",
    invoiceNo: "رقم الفاتورة",
    date: "التاريخ",
    member: "العضو",
    qty: "الكمية",
    price: "السعر",
    subTotal: "الإجمالي الفرعي",
    totalAmount: "المبلغ الإجمالي",
    discount: "الخصم",
    totalPrice: "السعر الكلي",
    grandTotal: "الإجمالي الكلي",
    discountTotal: "إجمالي الخصم",
    print: "طباعة",
    salesSummaryReport: "تقرير ملخص المبيعات",
    salesReturnReport: "تقرير مرتجعات المبيعات",
    referenceInvoice: "فاتورة مرجعية",
    product: "المنتج",
    memberWiseSalesReport: "تقرير المبيعات حسب العضو",
    return: "إرجاع",
    salesTotal: "إجمالي المبيعات",
    salesReturnTotal: "إجمالي مرتجعات المبيعات",
    sales: "المبيعات",
    creditSalesReport: "تقرير مبيعات الائتمان",
    collectionTotal: "إجمالي التحصيل",
    dueAmount: "المبلغ المستحق",
    memberWiseCreditSalesReport: "تقرير مبيعات الائتمان حسب العضو",
    purchaseReport: "تقرير الشراء",
    noOfBox: "عدد الصناديق",
    purchaseAmount: "مبلغ الشراء",
    totalPurchaseAmount: "إجمالي مبلغ الشراء",
    vatTotal: "إجمالي ضريبة القيمة المضافة",
    purchaseSummaryReport: "تقرير ملخص الشراء",
    supplier: "المورد",
    purchasePrice: "سعر الشراء",
    purchaseType: "نوع الشراء",
    purchaseReturnReport: "تقرير مرتجعات الشراء",
    returnQty: "كمية الإرجاع",
    returnAmount: "مبلغ الإرجاع",
    supplierDueReport: "تقرير مستحقات المورد",
    stockClosingReport: "تقرير إغلاق المخزون",
    totalStockAmount: "إجمالي كمية المخزون",
    stock: "المخزون",
    stockAdjustmentReport: "تقرير تعديل المخزون",
    inOut: "داخل/خارج",
    damageAmount: "مبلغ الضرر",
    stockExpiryReport: "تقرير انتهاء صلاحية المخزون",
    company: "الشركة",
    expiryDate: "تاريخ الانتهاء",
    profitLossReport: "تقرير الربح/الخسارة",
    totalSalesAmount: "إجمالي مبلغ المبيعات",
    totalDamageAmount: "إجمالي مبلغ الضرر",
    grossProfitLoss: "الربح/الخسارة الإجمالية",
    paymentMethod:"طريقة الدفع",
    vat : "ضريبة القيمة المضافة",
    paidAmount:"المبلغ المدفوع",
}
const bengali = {
    dailySalesReport: "দৈনিক বিক্রয় রিপোর্ট",
    startDate: "শুরুর তারিখ",
    endDate: "শেষ তারিখ",
    search: "অনুসন্ধান",
    sl: "ক্রমিক",
    invoiceNo: "চালান নং",
    date: "তারিখ",
    member: "সদস্য",
    qty: "পরিমাণ",
    price: "দাম",
    subTotal: "উপ মোট",
    totalAmount: "মোট পরিমাণ",
    discount: "ছাড়",
    totalPrice: "মোট মূল্য",
    grandTotal: "সর্বমোট",
    discountTotal: "ছাড়ের মোট",
    print: "প্রিন্ট",
    salesSummaryReport: "বিক্রয় সারসংক্ষেপ রিপোর্ট",
    salesReturnReport: "বিক্রয় রিটার্ন রিপোর্ট",
    referenceInvoice: "রেফারেন্স ইনভয়েস",
    product: "পণ্য",
    memberWiseSalesReport: "সদস্য অনুযায়ী বিক্রয় রিপোর্ট",
    return: "রিটার্ন",
    salesTotal: "বিক্রয়ের মোট",
    salesReturnTotal: "বিক্রয় রিটার্নের মোট",
    sales: "বিক্রয়",
    creditSalesReport: "ক্রেডিট বিক্রয় রিপোর্ট",
    collectionTotal: "সংগ্রহের মোট",
    dueAmount: "বকেয়া পরিমাণ",
    memberWiseCreditSalesReport: "সদস্য অনুযায়ী ক্রেডিট বিক্রয় রিপোর্ট",
    purchaseReport: "ক্রয় রিপোর্ট",
    noOfBox: "বক্সের সংখ্যা",
    purchaseAmount: "ক্রয়ের পরিমাণ",
    totalPurchaseAmount: "মোট ক্রয়ের পরিমাণ",
    vatTotal: "মোট ভ্যাট",
    purchaseSummaryReport: "ক্রয় সারসংক্ষেপ রিপোর্ট",
    supplier: "সরবরাহকারী",
    purchasePrice: "ক্রয় মূল্য",
    purchaseType: "ক্রয়ের ধরন",
    purchaseReturnReport: "ক্রয় রিটার্ন রিপোর্ট",
    returnQty: "রিটার্ন পরিমাণ",
    returnAmount: "রিটার্নের পরিমাণ",
    supplierDueReport: "সরবরাহকারীর বকেয়া রিপোর্ট",
    stockClosingReport: "স্টক বন্ধ রিপোর্ট",
    totalStockAmount: "মোট স্টকের পরিমাণ",
    stock: "স্টক",
    stockAdjustmentReport: "স্টক সমন্বয় রিপোর্ট",
    inOut: "ভিতরে/বাইরে",
    damageAmount: "ক্ষতির পরিমাণ",
    stockExpiryReport: "স্টক মেয়াদ উত্তীর্ণ রিপোর্ট",
    company: "কোম্পানি",
    expiryDate: "মেয়াদ শেষের তারিখ",
    profitLossReport: "লাভ/ক্ষতি রিপোর্ট",
    totalSalesAmount: "মোট বিক্রয় পরিমাণ",
    totalDamageAmount: "মোট ক্ষতির পরিমাণ",
    grossProfitLoss: "মোট লাভ/ক্ষতি",
    paymentMethod : "পেমেন্ট পদ্ধতি",
    vat : "ভ্যাট",
    paidAmount : "পরিশোধের পরিমাণ",
}

export const reportLang ={
    english , malay , thai , arabic , bengali
}