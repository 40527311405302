
const english = {
    welcome: "Welcome to Dashboard",
    customers: "CUSTOMERS",
    invoice: "INVOICE",
    stock: "STOCK",
    revenue: "REVENUE",
    revenueText : "Sales are activities related to selling or the number of goods or services sold in a given time period.",
    monthlySales: "MONTHLY SALES",
    salesText: "Sales are activities related to selling or the number of goods or services sold in a given time period.",
    cloudStorage: "CLOUD STORAGE",
    totalStorage: "TOTAL STORAGE",
    usedStorage: "USED STORAGE",
    jan: "JAN",
    feb: "FEB",
    mar: "MAR",
    apr: "APR",
    may: "MAY",
    jun: "JUN",
    jul: "JUL",
    aug: "AUG",
    sep: "SEP",
    oct: "OCT",
    nov: "NOV",
    dec: "DEC",
}

const malay = {
    welcome: "Selamat datang ke Papan Pemuka",
    customers: "PELANGGAN",
    invoice: "INVOIS",
    stock: "STOK",
    revenue: "HASIL",
    revenueText : "Jualan adalah aktiviti yang berkaitan dengan penjualan atau jumlah barangan atau perkhidmatan yang dijual dalam tempoh masa tertentu.",
    monthlySales: "JUALAN BULANAN",
    salesText: "Jualan adalah aktiviti yang berkaitan dengan penjualan atau jumlah barangan atau perkhidmatan yang dijual dalam tempoh masa tertentu.",
    cloudStorage: "STORAN AWAN",
    totalStorage: "JUMLAH STORAN",
    usedStorage: "STORAN DIGUNAKAN",
    jan: "JAN",
    feb: "FEB",
    mar: "MAC",
    apr: "APR",
    may: "MEI",
    jun: "JUN",
    jul: "JUL",
    aug: "OGOS",
    sep: "SEP",
    oct: "OKT",
    nov: "NOV",
    dec: "DIS",
}

const thai = {
    welcome: "ยินดีต้อนรับสู่แดชบอร์ด",
    customers: "ลูกค้า",
    invoice: "ใบแจ้งหนี้",
    stock: "สต็อก",
    revenue: "รายได้",
    revenueText : "การขายคือกิจกรรมที่เกี่ยวข้องกับการขายหรือจำนวนสินค้าหรือบริการที่ขายในช่วงระยะเวลาที่กำหนด",
    monthlySales: "ยอดขายรายเดือน",
    salesText: "การขายคือกิจกรรมที่เกี่ยวข้องกับการขายหรือจำนวนสินค้าหรือบริการที่ขายในช่วงระยะเวลาที่กำหนด",
    cloudStorage: "พื้นที่จัดเก็บบนคลาวด์",
    totalStorage: "พื้นที่จัดเก็บทั้งหมด",
    usedStorage: "พื้นที่จัดเก็บที่ใช้แล้ว",
    jan: "ม.ค.",
    feb: "ก.พ.",
    mar: "มี.ค.",
    apr: "เม.ย.",
    may: "พ.ค.",
    jun: "มิ.ย.",
    jul: "ก.ค.",
    aug: "ส.ค.",
    sep: "ก.ย.",
    oct: "ต.ค.",
    nov: "พ.ย.",
    dec: "ธ.ค.",
}

const arabic = {
    welcome: "مرحبًا بك في لوحة القيادة",
    customers: "العملاء",
    invoice: "فاتورة",
    stock: "المخزون",
    revenue: "الإيرادات",
    revenueText : "المبيعات هي الأنشطة المتعلقة بالبيع أو عدد السلع أو الخدمات المباعة في فترة زمنية معينة.",
    monthlySales: "المبيعات الشهرية",
    salesText: "المبيعات هي الأنشطة المتعلقة بالبيع أو عدد السلع أو الخدمات المباعة في فترة زمنية معينة.",
    cloudStorage: "التخزين السحابي",
    totalStorage: "إجمالي التخزين",
    usedStorage: "التخزين المستخدم",
    jan: "يناير",
    feb: "فبراير",
    mar: "مارس",
    apr: "أبريل",
    may: "مايو",
    jun: "يونيو",
    jul: "يوليو",
    aug: "أغسطس",
    sep: "سبتمبر",
    oct: "أكتوبر",
    nov: "نوفمبر",
    dec: "ديسمبر",
}

const bengali = {
    welcome: "ড্যাশবোর্ডে স্বাগতম",
    customers: "গ্রাহকরা",
    invoice: "চালান",
    stock: "স্টক",
    revenue: "রাজস্ব",
    revenueText : "বিক্রয় এমন কার্যকলাপ যা বিক্রয়ের সাথে সম্পর্কিত বা নির্দিষ্ট সময়ের মধ্যে বিক্রি হওয়া পণ্য বা পরিষেবার সংখ্যা নির্দেশ করে।",
    monthlySales: "মাসিক বিক্রয়",
    salesText: "বিক্রয় এমন কার্যকলাপ যা বিক্রয়ের সাথে সম্পর্কিত বা নির্দিষ্ট সময়ের মধ্যে বিক্রি হওয়া পণ্য বা পরিষেবার সংখ্যা নির্দেশ করে।",
    cloudStorage: "ক্লাউড স্টোরেজ",
    totalStorage: "মোট স্টোরেজ",
    usedStorage: "ব্যবহৃত স্টোরেজ",
    jan: "জানু",
    feb: "ফেব্রু",
    mar: "মার্চ",
    apr: "এপ্রিল",
    may: "মে",
    jun: "জুন",
    jul: "জুলাই",
    aug: "আগস্ট",
    sep: "সেপ্টে",
    oct: "অক্টো",
    nov: "নভে",
    dec: "ডিসে",
}

export const dashboardLang = {
    english: english,
    malay: malay,
    thai: thai,
    arabic: arabic,
    bengali: bengali
}