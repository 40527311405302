const english = {
    pageTitle: "Products List",
    addProduct: "Add Products",
    editProduct: "Edit Products",
    title: "Product Name",
    category: "Category",
    mrpPrice: "MRP Price",
    purchasePrice: "Purchase Price",
    itemCode: "Item Code",
    action: "Action",
    edit: "Edit",
    delete: "Delete",
    deleteConfirm: "Are you sure?",
    deleteText: "You won't be able to revert this!",
    yes: "Yes, delete it!",
    no: "No",
    cancel: "Cancel",
    status: "Status",
    select: "Select",
    active: "Active",
    inactive: "Inactive",
    img: "Image size must be less than 2 mb",
    preview: "preview image",
    sl: "SL",
    update: "Update",
    save: "Save",
    back: "Back",
    message: "Your data has been deleted.",
    deleted: "Deleted!",
    photo: "Photo",
    productDetails: "Product Details",
    productName: "Product Name",
    productCode: "Product Code",
    brand: "Brand",
    group: "Group",
    subCategory: "Sub Category",
    unit: "Unit",
    boxType: "Box Type",
    packetSize: "Packet Size",
    expiryDate: "Expiry Date",
    class: "Class",
    batch: "Batch",
    mainImage: "Main Image",
    multipleImage: "Multiple Image",
    productDescription: "Product Description",
    addMore: "Add More",
    description: "Description",
    purchaseError: "Purchase Price can't be greater than MRP Price",
    remove: "Remove"
}
const malay = {
    pageTitle: "Senarai Produk",
    addProduct: "Tambah Produk",
    editProduct: "Sunting Produk",
    title: "Nama Produk",
    category: "Kategori",
    mrpPrice: "Harga MRP",
    purchasePrice: "Harga Pembelian",
    itemCode: "Kod Item",
    action: "Tindakan",
    edit: "Sunting",
    delete: "Padam",
    deleteConfirm: "Adakah anda pasti?",
    deleteText: "Anda tidak akan dapat mengembalikannya!",
    yes: "Ya, padamkan!",
    no: "Tidak",
    cancel: "Batal",
    status: "Status",
    select: "Pilih",
    active: "Aktif",
    inactive: "Tidak Aktif",
    img: "Saiz imej mesti kurang daripada 2 mb",
    preview: "pratonton imej",
    sl: "No",
    update: "Kemas Kini",
    save: "Simpan",
    back: "Kembali",
    message: "Data anda telah dipadam.",
    deleted: "Dipadam!",
    photo: "Foto",
    productDetails: "Butiran Produk",
    productName: "Nama Produk",
    productCode: "Kod Produk",
    brand: "Jenama",
    group: "Kumpulan",
    subCategory: "Sub Kategori",
    unit: "Unit",
    boxType: "Jenis Kotak",
    packetSize: "Saiz Pek",
    expiryDate: "Tarikh Luput",
    class: "Kelas",
    batch: "Kumpulan",
    mainImage: "Imej Utama",
    multipleImage: "Imej Pelbagai",
    productDescription: "Deskripsi Produk",
    addMore: "Tambah Lagi",
    description: "Deskripsi",
    purchaseError: "Harga Pembelian tidak boleh lebih tinggi daripada Harga MRP",
    remove: "Buang"
};

const thai = {
    pageTitle: "รายการสินค้า",
    addProduct: "เพิ่มสินค้า",
    editProduct: "แก้ไขสินค้า",
    title: "ชื่อสินค้า",
    category: "หมวดหมู่",
    mrpPrice: "ราคาป้าย",
    purchasePrice: "ราคาซื้อ",
    itemCode: "รหัสสินค้า",
    action: "การดำเนินการ",
    edit: "แก้ไข",
    delete: "ลบ",
    deleteConfirm: "คุณแน่ใจหรือไม่?",
    deleteText: "คุณจะไม่สามารถย้อนกลับได้!",
    yes: "ใช่, ลบเลย!",
    no: "ไม่",
    cancel: "ยกเลิก",
    status: "สถานะ",
    select: "เลือก",
    active: "ใช้งาน",
    inactive: "ไม่ใช้งาน",
    img: "ขนาดรูปภาพต้องน้อยกว่า 2 mb",
    preview: "ดูตัวอย่างรูปภาพ",
    sl: "ลำดับ",
    update: "อัปเดต",
    save: "บันทึก",
    back: "กลับ",
    message: "ข้อมูลของคุณถูกลบแล้ว.",
    deleted: "ลบแล้ว!",
    photo: "รูปภาพ",
    productDetails: "รายละเอียดสินค้า",
    productName: "ชื่อสินค้า",
    productCode: "รหัสสินค้า",
    brand: "ยี่ห้อ",
    group: "กลุ่ม",
    subCategory: "หมวดหมู่ย่อย",
    unit: "หน่วย",
    boxType: "ประเภทกล่อง",
    packetSize: "ขนาดแพ็ค",
    expiryDate: "วันหมดอายุ",
    class: "คลาส",
    batch: "ชุด",
    mainImage: "ภาพหลัก",
    multipleImage: "ภาพหลายภาพ",
    productDescription: "รายละเอียดสินค้า",
    addMore: "เพิ่มอีก",
    description: "คำอธิบาย",
    purchaseError: "ราคาซื้อไม่สามารถมากกว่าราคาป้าย",
    remove: "ลบออก"
};

const arabic = {
    pageTitle: "قائمة المنتجات",
    addProduct: "إضافة منتجات",
    editProduct: "تعديل المنتجات",
    title: "اسم المنتج",
    category: "الفئة",
    mrpPrice: "سعر التجزئة",
    purchasePrice: "سعر الشراء",
    itemCode: "رمز العنصر",
    action: "الإجراء",
    edit: "تعديل",
    delete: "حذف",
    deleteConfirm: "هل أنت متأكد؟",
    deleteText: "لن تتمكن من التراجع عن هذا!",
    yes: "نعم، احذفه!",
    no: "لا",
    cancel: "إلغاء",
    status: "الحالة",
    select: "اختر",
    active: "نشط",
    inactive: "غير نشط",
    img: "يجب أن يكون حجم الصورة أقل من 2 ميغابايت",
    preview: "معاينة الصورة",
    sl: "تسلسل",
    update: "تحديث",
    save: "حفظ",
    back: "رجوع",
    message: "تم حذف بياناتك.",
    deleted: "تم الحذف!",
    photo: "صورة",
    productDetails: "تفاصيل المنتج",
    productName: "اسم المنتج",
    productCode: "رمز المنتج",
    brand: "علامة تجارية",
    group: "مجموعة",
    subCategory: "فئة فرعية",
    unit: "وحدة",
    boxType: "نوع الصندوق",
    packetSize: "حجم العبوة",
    expiryDate: "تاريخ انتهاء الصلاحية",
    class: "الفئة",
    batch: "دفعة",
    mainImage: "الصورة الرئيسية",
    multipleImage: "صور متعددة",
    productDescription: "وصف المنتج",
    addMore: "أضف المزيد",
    description: "وصف",
    purchaseError: "لا يمكن أن يكون سعر الشراء أكبر من سعر التجزئة",
    remove: "إزالة"
};

const bengali = {
    pageTitle: "পণ্য তালিকা",
    addProduct: "পণ্য যোগ করুন",
    editProduct: "পণ্য সম্পাদনা করুন",
    title: "পণ্যের নাম",
    category: "বিভাগ",
    mrpPrice: "এমআরপি মূল্য",
    purchasePrice: "ক্রয় মূল্য",
    itemCode: "আইটেম কোড",
    action: "কর্ম",
    edit: "সম্পাদনা",
    delete: "মুছুন",
    deleteConfirm: "আপনি কি নিশ্চিত?",
    deleteText: "আপনি এটি পুনরুদ্ধার করতে পারবেন না!",
    yes: "হ্যাঁ, মুছে ফেলুন!",
    no: "না",
    cancel: "বাতিল",
    status: "অবস্থা",
    select: "নির্বাচন করুন",
    active: "সক্রিয়",
    inactive: "নিষ্ক্রিয়",
    img: "ছবির আকার 2 এমবি এর চেয়ে কম হতে হবে",
    preview: "ছবির পূর্বরূপ",
    sl: "ক্রমিক",
    update: "আপডেট",
    save: "সংরক্ষণ",
    back: "ফিরে যান",
    message: "আপনার তথ্য মুছে ফেলা হয়েছে।",
    deleted: "মুছে ফেলা হয়েছে!",
    photo: "ছবি",
    productDetails: "পণ্যের বিবরণ",
    productName: "পণ্যের নাম",
    productCode: "পণ্যের কোড",
    brand: "ব্র্যান্ড",
    group: "গ্রুপ",
    subCategory: "উপবিভাগ",
    unit: "একক",
    boxType: "বাক্সের ধরন",
    packetSize: "প্যাকেটের আকার",
    expiryDate: "মেয়াদ উত্তীর্ণের তারিখ",
    class: "শ্রেণী",
    batch: "ব্যাচ",
    mainImage: "প্রধান চিত্র",
    multipleImage: "একাধিক চিত্র",
    productDescription: "পণ্যের বিবরণ",
    addMore: "আরও যোগ করুন",
    description: "বর্ণনা",
    purchaseError: "ক্রয় মূল্য এমআরপি মূল্যের চেয়ে বেশি হতে পারে না",
    remove: "অপসারণ"
};

export const productLang = {
    english,
    malay,
    thai,
    arabic,
    bengali
}